var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "payment-page" }, [
    _vm.showPayment === 0
      ? _c(
          "span",
          [
            _c(
              "el-row",
              {
                staticClass: "row-bg",
                attrs: { type: "flex", justify: "center" },
              },
              [
                _c(
                  "el-col",
                  { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                  [
                    _c("div", { staticClass: "container" }, [
                      _c("div", { staticClass: "logo" }),
                      _c("div", { staticClass: "description" }, [
                        _vm._v(
                          " Please provide the email address you received via the link to verify your identity. "
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "email" },
                        [
                          _c("el-input", {
                            staticClass: "item",
                            staticStyle: { height: "50px" },
                            attrs: {
                              size: "large",
                              placeholder: "Email address",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.getPreparation.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.email,
                              callback: function ($$v) {
                                _vm.email = $$v
                              },
                              expression: "email",
                            },
                          }),
                          _c(
                            "el-button",
                            {
                              staticClass: "item",
                              attrs: {
                                disabled: _vm.email === "" || _vm.isVerifying,
                                type: "primary",
                                size: "large",
                                loading: _vm.isVerifying,
                              },
                              on: { click: _vm.getPreparation },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.isVerifying ? "Verify..." : "Verify"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.showPayment === 1
      ? _c(
          "span",
          [
            _c(
              "el-row",
              {
                staticClass: "row-bg",
                attrs: { type: "flex", justify: "center" },
              },
              [
                _c(
                  "el-col",
                  { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                  [
                    _c("div", { staticClass: "container" }, [
                      _c("div", { staticClass: "logo" }),
                      _c("div", { staticClass: "description" }, [
                        _vm._v(
                          " Please complete your payment to authorise us to begin processing this check. "
                        ),
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _c("span", { staticClass: "row" }, [
                          _c("div", { staticClass: "left" }, [
                            _vm._v("Requested On"),
                          ]),
                          _c("div", { staticClass: "right" }, [
                            _vm._v(
                              _vm._s(
                                _vm.convertUTC2LocalDateTime(
                                  _vm.details.createdOn
                                )
                              )
                            ),
                          ]),
                        ]),
                        _c("span", { staticClass: "row" }, [
                          _c("div", { staticClass: "left" }, [
                            _vm._v("Applicant Name"),
                          ]),
                          _c("div", { staticClass: "right" }, [
                            _vm._v(
                              _vm._s(_vm.details.applicantFirstName) +
                                " " +
                                _vm._s(_vm.details.applicantLastName)
                            ),
                          ]),
                        ]),
                        _c("span", { staticClass: "row" }, [
                          _c("div", { staticClass: "left" }, [
                            _vm._v("Reference Number"),
                          ]),
                          _c("div", { staticClass: "right" }, [
                            _vm._v(_vm._s(_vm.details.referenceNumber)),
                          ]),
                        ]),
                      ]),
                      _c("form", [
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "elements" },
                            [
                              _c("div", { attrs: { id: "payment-element" } }),
                              _c("div", { staticClass: "price-container" }, [
                                _c("div", { staticClass: "price" }, [
                                  _vm._v(
                                    " AUD $" +
                                      _vm._s(
                                        _vm.details.policeCheckForRecruitmentPrice.toFixed(
                                          2
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("div", { staticClass: "price-subtitle" }, [
                                  _vm._v(" (includes GST) "),
                                ]),
                              ]),
                              _c(
                                "el-button",
                                {
                                  staticClass: "button",
                                  attrs: {
                                    type: "submit",
                                    size: "large",
                                    loading: _vm.isProcessing,
                                  },
                                  on: { click: _vm.handleSubmit },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.isProcessing
                                          ? "Processing..."
                                          : "Pay Now"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "contact" }, [
                                _c("div", [
                                  _vm._v(
                                    "If you have any queries please contact our office on "
                                  ),
                                ]),
                                _c("span", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "a-link",
                                      attrs: { href: "tel:1300 557 556" },
                                    },
                                    [_vm._v("1300 557 556")]
                                  ),
                                ]),
                                _c("span", [_vm._v(" or ")]),
                                _c("span", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "a-link",
                                      attrs: {
                                        href: "mailto:support@precisebackground.com",
                                      },
                                    },
                                    [_vm._v("support@precisebackground.com")]
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", {
                        attrs: { id: "card-errors", role: "alert" },
                      }),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.showPayment === 2
      ? _c(
          "span",
          [
            _c(
              "el-row",
              {
                staticClass: "row-bg",
                attrs: { type: "flex", justify: "center" },
              },
              [
                _c(
                  "el-col",
                  { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                  [
                    _c("div", { staticClass: "container" }, [
                      _c("div", { staticClass: "logo" }),
                      _c("div", { staticClass: "description" }, [
                        _vm._v(" The payment has been completed. "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "email" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "item",
                              attrs: { type: "primary", size: "large" },
                              on: { click: _vm.redirectLogin },
                            },
                            [_vm._v(" Log in ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }