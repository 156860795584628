// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/imgs/login-logo.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.thank-you {\n  width: 100%;\n  min-height: 100%;\n  background: #206baa;\n  display: flex;\n  justify-content: center;\n.container {\n    margin: 160px 20px 20px 20px;\n    padding: 30px 30px 50px 30px;\n    max-width: 700px;\n    background: white;\n    border-radius: 6px;\n    text-align: center;\n.title {\n      font-size: 25px;\n      text-align: left;\n      padding: 30px 50px;\n}\n.description {\n      padding: 10px 50px;\n      font-size: 15px;\n      line-height: 20px;\n      text-align: left;\n}\n}\n.logo {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    height: 106px;\n    width: 197px;\n    margin: auto;\n}\n}\n\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
