var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "MedicalDeclarationView",
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.ruleForm },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "consentToShare" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "label",
                      attrs: { xs: 24, sm: 24, md: 24, lg: 16, xl: 16 },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                      _vm._v(
                        " Would you like Precise Background Services to share a copy of your Nationally Coordinate Criminal History Check result to "
                      ),
                      _c("b", [_vm._v(_vm._s(this.ruleForm.organisationName))]),
                      _vm._v(
                        ", including any authorised third parties involved in the background check on your behalf? "
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.ruleForm.consentToShare,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "consentToShare", $$v)
                            },
                            expression: "ruleForm.consentToShare",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }