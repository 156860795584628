// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/imgs/login-logo.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.payment-page {\n  width: 100%;\n  min-height: 100%;\n  background: #206baa;\n  display: flex;\n  justify-content: center;\n.container {\n    margin: 160px 20px 20px 20px;\n    padding: 10px;\n    max-width: 500px;\n    background: white;\n    border-radius: 6px;\n    text-align: center;\n.info {\n      display: flex;\n      flex-direction: column;\n      padding: 50px;\n.row {\n        display: flex;\n        justify-content: space-between;\n        margin: 3px 0;\n.left {\n          text-align: left;\n          color: #828693;\n          font-weight: 500;\n          font-size: 15px;\n          min-width: 100px;\n}\n.right {\n          text-align: right;\n          font-size: 15px;\n          min-width: 100px;\n}\n}\n}\n}\n.logo {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    height: 106px;\n    width: 197px;\n    margin: 40px auto;\n}\n.description {\n    padding: 5px 50px;\n    font-size: 15px;\n}\n.email {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    margin: 20px 0 50px 0;\n.item {\n      width: 80%;\n      max-width: 800px;\n      margin: auto;\n}\n}\n.elements {\n    padding: 0 50px;\n    max-width: 800px;\n    display: flex;\n    flex-direction: column;\n.price-container {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      height: 60px;\n.price {\n        font-size: 25px;\n        font-weight: 500;\n}\n.price-subtitle {\n        font-size: 13px;\n        margin: 0 5px;\n}\n}\n.button {\n      margin: 20px 0;\n}\n#payment-element {\n      margin: 20px 0;\n.country-field {\n        display: none;\n}\n}\n}\n.contact{\n    margin: 10px 0 40px 0;\n    //font-weight: 500;\n    font-size: 14px;\n    line-height: 18px;\n}\n}\n\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
