import service from '@/utils/request'
const api = process.env.NODE_ENV !== 'development' ? '/api/applicant/payment' : '/proxy/api/applicant/payment'

export function paymentPreparation(data){
  return service({
    url: `${api}/preparation`,
    method: 'post',
    data
  })
}
export function createPaymentIntent(data) {
  return service({
    url: `${api}/create-payment-intent`,
    method: 'post',
    data
  })
}
