<script>

export default {
  name: 'PaymentThankYou'
}
</script>

<template>
  <div class="thank-you">
    <span>
      <el-row type="flex" class="row-bg" justify="center">
        <el-col
          :xs="24" :sm="24" :md="24" :lg="24"
          :xl="24"
        >
          <div class="container">
            <div class="logo" />
            <div class="title">
              Thank you!
            </div>
            <div class="description">
              You’ll soon get an email with your tax receipt and another asking you to activate your PBS Portal account.
            </div>
            <div class="description">
              Check your junk mailbox if you can’t find our email.
            </div>
            <div class="description">
              If you haven’t received these emails after 24 hours, contact us to have them resent to you.
            </div>
          </div>
        </el-col>
      </el-row>
    </span>
  </div>
</template>
<style>
.thank-you {
  width: 100%;
  min-height: 100%;
  background: #206baa;
  display: flex;
  justify-content: center;

  .container {
    margin: 160px 20px 20px 20px;
    padding: 30px 30px 50px 30px;
    max-width: 700px;
    background: white;
    border-radius: 6px;
    text-align: center;

    .title {
      font-size: 25px;
      text-align: left;
      padding: 30px 50px;
    }

    .description {
      padding: 10px 50px;
      font-size: 15px;
      line-height: 20px;
      text-align: left;
    }
  }

  .logo {
    background-image: url("@/assets/imgs/login-logo.svg");
    height: 106px;
    width: 197px;
    margin: auto;
  }

}

</style>
