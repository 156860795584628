<script>
import { mapState } from 'vuex'
import { autoCompleteApi, confirmApi, preparationApi } from '@/api/PoliceResult'
import { convertUTC2LocalDateTime } from '@/utils'
import { generateRepoortTokenApi } from '@/api/application'

export default {
  name: 'PoliceReportDialog',
  props: ['applicantId', 'referenceNumber', 'orderApplicantId', 'organisationId'],
  data() {
    return {
      showDialog: false,
      loading: false,
      buttonLoading: false,
      dialogData: {
        resultType: '',
        showResult: false,
        checkStatus: '',
        checkStatusLabel: '',
        lastConfirmationDateTime: null,
        events: []
      },
      timeRemaining: null,
      formattedTime: '-',
      showResult: false,
      showConfirmation: false,
      disputeSelection: null,
      disputeInput: ''
    }
  },
  computed: {
    ...mapState({
      device: (state) => state.setting.device
    })
  },
  methods: {
    convertUTC2LocalDateTime,
    handleShowDialog() {
      this.showDialog = true
      this.loading = true
      const reqData = {
        orderApplicantId: this.orderApplicantId
      }
      preparationApi(reqData).then(res => {
        const { data } = res
        this.dialogData = data
        if (data.confirmationTimeRemaining) {
          this.timeRemaining = data.confirmationTimeRemaining
          this.formattedTime = this.formatTime(this.timeRemaining)
          this.showConfirmation = true
          this.startCountdown()
        } else {
          this.timeRemaining = null
          this.showConfirmation = false
        }
        this.loading = false
      })
    },
    startCountdown() {
      this.intervalId = setInterval(() => {
        if (this.timeRemaining >= 1000) {
          this.timeRemaining -= 1000
          this.formattedTime = this.formatTime(this.timeRemaining)
          this.$forceUpdate()
        } else {
          clearInterval(this.intervalId)
          this.showConfirmation = false
          this.formattedTime = '-'
          this.handleAutoCompleteApi()
        }
      }, 1000)
    },
    formatTime(time) {
      const hours = Math.floor(time / 60000 / 60)
      const minutes = Math.floor(time / 60000 % 60)
      const seconds = Math.floor((time % 60000) / 1000)
      let formatedTime = ''
      if (hours > 0) formatedTime += `${hours} hours `
      if (hours > 0 || minutes > 0) formatedTime += `${minutes} minutes `
      if (hours > 0 || minutes > 0 || seconds > 0) formatedTime += `${seconds.toString().padStart(2, '0')} seconds`
      return formatedTime
    },
    clearTimer(event) {
      clearInterval(this.intervalId)
      this.formattedTime = '-'
      this.showDialog = false
      this.disputeInput = ''
      this.disputeSelection = null
    },
    handleConfirm() {
      const reqData = {
        orderApplicantId: this.orderApplicantId,
        disputeSelection: this.disputeSelection,
        disputeReason: this.disputeInput
      }
      confirmApi(reqData).then(res => {
        this.clearTimer()
        const { data } = res
        this.dialogData = data
        if (data.confirmationTimeRemaining) {
          this.timeRemaining = data.confirmationTimeRemaining
          this.formattedTime = this.formatTime(this.timeRemaining)
          this.showConfirmation = true
          this.startCountdown()
        } else {
          this.timeRemaining = null
          this.showConfirmation = false
        }
        this.loading = false
      })
    },
    handleAutoCompleteApi() {
      this.loading = true
      const reqData = {
        orderApplicantId: this.orderApplicantId
      }
      autoCompleteApi(reqData).then(res => {
        const { data } = res
        this.dialogData = data
        this.loading = false
      })
    },
    async generateReportToken(params) {
      let token = ''
      const { data } = await generateRepoortTokenApi(params)
      token = data.token
      return token
    },
    async ViewReport() {
      this.buttonLoading = true
      let token = null
      const params = {
        OrganisationId: this.organisationId,
        ApplicantId: this.applicantId,
        OrderApplicantId: this.orderApplicantId
      }
      await this.generateReportToken(params).then((res) => {
        token = res
        this.downloadPDF(token, this.referenceNumber)
      }).catch(() => {
        this.buttonLoading = false
      })
    },
    async downloadPDF(token, referenceNumber) {
      const url = `${process.env.VUE_APP_BASE_API}/api/pbs-report/police-report?token=${token}`
      const filename = `${referenceNumber}_Report.pdf`
      return fetch(url).then(res => res.blob().then(blob => {
        const a = document.createElement('a')
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = filename
        a.click()
        window.URL.revokeObjectURL(url)
        this.buttonLoading = false
      }))
    }
  }
}
</script>
<template>
  <div style="display: flex; justify-content: flex-end;">
    <span style="margin: 2px;">
      <el-button type="secondary" @click="handleShowDialog"
                 style="color:white; background: #37b24d !important;border-color:#37b24d;"
      >
        <i class="fa fa-gavel" style="margin-right: 10px;" />Police Report</el-button>
    </span>
    <span>
      <el-dialog
        ref="dialogEntity"
        class="dialog"
        :lock-scroll="false"
        :append-to-body="true"
        :visible.sync="showDialog"
        :width="device === 'mobile'?'90%':'900px'"
        :before-close="clearTimer"
      >
        <div v-loading="loading">
          <div slot="title" class="dialog-title f-w-600">PBS Ref {{ referenceNumber }}</div>
          <div class="status">{{ dialogData.checkStatusLabel }}</div>
          <div
            v-if="dialogData.showResult" class="text-l"
            style="background: #F1F5F9; border-radius: 5px; border: #E2E8F0 solid 1px; padding: 20px; margin: 10px 0;"
          >
            <span style="display: flex; justify-content: space-between; align-items: center">
              <span>
                <div class="f-w-600" style="margin-bottom: 5px;">Police Result</div>
                <div v-if="dialogData.resultType === 'DCO'">Disclosable Court Outcomes (DCO)</div>
                <div v-if="dialogData.resultType === 'NDCO'">No Disclosable Court Outcomes (NDCO)</div>
              </span>
              <span><el-button :loading="buttonLoading" @click="ViewReport">Download</el-button></span>
            </span>
            <div v-if="showConfirmation" class="text-l">
              <el-divider />
              <p>Please review above police result and confirm your need of dispute.</p>
              <div style="margin: 10px 0;">The system will automatically confirm the result without any need for dispute after <b>{{ formattedTime
                }}</b></div>
              <div style="margin: 10px 0;">
                <p>Would you like to raise a dispute</p>
                <el-radio-group v-model="disputeSelection" style="margin-top: 10px;">
                  <el-radio :label="false">No</el-radio>
                  <el-radio :label="true">Yes</el-radio>
                </el-radio-group>
              </div>
              <span v-if="disputeSelection">
                <el-input
                  v-model="disputeInput" placeholder="Please provide details about this dispute." type="textarea"
                  maxlength="3000"
                  :autosize="{ minRows: 4 }"
                />
              </span>
              <el-button
                :disabled="(disputeSelection === null) || (disputeSelection && disputeInput === '')"
                style="margin-top: 10px"
                type="primary" @click="handleConfirm"
              >Confirm</el-button>
            </div>
          </div>
          <span v-if="dialogData.events.length > 0">
            <div class="f-w-600" style="margin-top: 20px;">Event History</div>
            <el-table
              ref="tTable"
              :data="dialogData.events"
              highlight-current-row
              style="width: 100%; margin-top: 10px;"
              height="350"
              border
            >
              <el-table-column prop="createdOn" label="Time" width="170">
                <template slot-scope="scope">
                  <div style="font-weight: 500">{{ convertUTC2LocalDateTime(scope.row.createdOn) }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="eventType" label="Event">
                <template slot-scope="scope">
                  <div style="font-weight: 500">{{ scope.row.eventType }}</div>
                  <div style="font-style: italic;">{{ scope.row.notes }}</div>
                </template>
              </el-table-column>
            </el-table>
          </span>
        </div>
      </el-dialog>
    </span>
  </div>
</template>
<style lang="scss" scoped>
.status {
  font-size: 23px;
  font-weight: 500;
  margin-top: 10px;
  height: 40px;
  line-height: 40px;
}

::v-deep .el-dialog__body {
  padding: 0 20px 10px;

  p {
    margin: 0;
  }
}
</style>
