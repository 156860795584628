<script>

import { loadStripe } from '@stripe/stripe-js'
import { createPaymentIntent, paymentPreparation } from '@/api/Payment'
import { convertUTC2LocalDateTime } from '@/utils'

export default {
  name: 'PaymentPage',
  data() {
    return {
      stripe: null,
      elements: null,
      cardErrors: '',
      clientSecret: '',
      email: '',
      details: {
        referenceNumber: null,
        createdOn: null,
        applicantFirstName: null,
        applicantLastName: null,
        isApplicantPaymentRequired: null,
        isApplicantPaymentFinished: null,
        organisationName: null,
        applicantEmail: null,
        policeCheckForRecruitmentPrice: 0
      },
      publishableApiKey: '',
      // Status
      showPayment: 0, // 0: Verify email page; 1: Payment page; 2: No payment is required
      isProcessing: false,
      isVerifying: false
    }
  },
  computed: {
    routerQuery() {
      const hash = location.href
      const p = hash.split('?')[1]
      const arr = new URLSearchParams(p)
      return arr.get('token') || null
    }
  },
  mounted() {
  },
  methods: {
    convertUTC2LocalDateTime,
    getPreparation() {
      this.isVerifying = true
      const reqData = {
        token: this.routerQuery,
        email: this.email
      }
      paymentPreparation(reqData).then(res => {
        const { data } = res
        this.isVerifying = false
        this.details = data.details
        this.publishableApiKey = data.stripePublishableApiKey
        if (this.details.isApplicantPaymentRequired && !this.details.isApplicantPaymentFinished) {
          this.requestPaymentIntent().then(res => {
            this.showPayment = 1
          })
        } else {
          this.showPayment = 2
        }
      }).catch(err => {
        this.isVerifying = false
      })
    },
    async handleSubmit() {
      this.isProcessing = true
      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              address: {
                country: 'AU'
              }
            }
          },
          receipt_email: this.email,
          return_url: `${process.env['VUE_APP_BASE_URL']}/payment-thank-you` // 替换为你的实际页面
        }
      })
      this.isProcessing = false
      if (error) {
        console.error(error)
        alert('Payment failed: ' + error.message)
      } else {
        alert('Payment succeeded!')
      }
    },
    async requestPaymentIntent() {
      this.stripe = await loadStripe(this.publishableApiKey)

      const reqData = {
        token: this.routerQuery
      }
      createPaymentIntent(reqData).then(res => {
        const { data } = res
        const clientSecret = data.clientSecret
        const appearance = {}
        this.elements = this.stripe.elements({ clientSecret, appearance })

        const paymentElement = this.elements.create('payment', {
          fields: {
            billingDetails: {
              address: {
                country: 'never' // 隐藏国家字段
              }
            }
          }
        })
        paymentElement.mount('#payment-element')
      })
    },
    redirectLogin() {
      this.$router.push({ path: `/login?id=${this.routerQuery}` })
    }
  }
}
</script>

<template>
  <div class="payment-page">
    <span v-if="showPayment === 0">
      <el-row type="flex" class="row-bg" justify="center">
        <el-col
          :xs="24" :sm="24" :md="24" :lg="24"
          :xl="24"
        >
          <div class="container">
            <div class="logo" />
            <div class="description">
              Please provide the email address you received via the link to verify your identity.
            </div>
            <div class="email">
              <el-input
                v-model="email" class="item" style="height: 50px;" size="large"
                placeholder="Email address" @keyup.enter.native="getPreparation"
              />
              <el-button
                :disabled="email==='' || isVerifying" class="item" type="primary" size="large"
                :loading="isVerifying" @click="getPreparation"
              >
                {{ isVerifying ? 'Verify...' : 'Verify' }}
              </el-button>
            </div>
          </div>
        </el-col>
      </el-row>
    </span>
    <span v-if="showPayment === 1">
      <el-row type="flex" class="row-bg" justify="center">
        <el-col
          :xs="24" :sm="24" :md="24" :lg="24"
          :xl="24"
        >
          <div class="container">
            <div class="logo" />
            <div class="description">
              Please complete your payment to authorise us to begin processing this check.
            </div>
            <div class="info">
              <span class="row">
                <div class="left">Requested On</div>
                <div class="right">{{ convertUTC2LocalDateTime(details.createdOn) }}</div>
              </span>
              <span class="row">
                <div class="left">Applicant Name</div>
                <div class="right">{{ details.applicantFirstName }} {{ details.applicantLastName }}</div>
              </span>
              <span class="row">
                <div class="left">Reference Number</div>
                <div class="right">{{ details.referenceNumber }}</div>
              </span>
            </div>
            <form>
              <div>
                <div class="elements">
                  <div id="payment-element" />
                  <div class="price-container">
                    <div class="price">
                      AUD ${{ details.policeCheckForRecruitmentPrice.toFixed(2) }}
                    </div>
                    <div class="price-subtitle">
                      (includes GST)
                    </div>
                  </div>
                  <el-button
                    class="button" type="submit" size="large" :loading="isProcessing"
                    @click="handleSubmit"
                  >
                    {{ isProcessing ? 'Processing...' : 'Pay Now' }}
                  </el-button>
                  <div class="contact">
                    <div>If you have any queries please contact our office on </div>
                    <span><a class="a-link" href="tel:1300 557 556">1300 557 556</a></span>
                    <span> or </span>
                    <span><a class="a-link" href="mailto:support@precisebackground.com">support@precisebackground.com</a></span>
                  </div>
                </div>
              </div>
            </form>
            <div id="card-errors" role="alert" />
          </div>
        </el-col>
      </el-row>
    </span>
    <span v-if="showPayment === 2">
      <el-row type="flex" class="row-bg" justify="center">
        <el-col
          :xs="24" :sm="24" :md="24" :lg="24"
          :xl="24"
        >
          <div class="container">
            <div class="logo" />
            <div class="description">
              The payment has been completed.
            </div>
            <div class="email">
              <el-button
                class="item" type="primary" size="large" @click="redirectLogin"
              >
                Log in
              </el-button>
            </div>
          </div>
        </el-col>
      </el-row>
    </span>

  </div>
</template>
<style>
.payment-page {
  width: 100%;
  min-height: 100%;
  background: #206baa;
  display: flex;
  justify-content: center;

  .container {
    margin: 160px 20px 20px 20px;
    padding: 10px;
    max-width: 500px;
    background: white;
    border-radius: 6px;
    text-align: center;

    .info {
      display: flex;
      flex-direction: column;
      padding: 50px;

      .row {
        display: flex;
        justify-content: space-between;
        margin: 3px 0;

        .left {
          text-align: left;
          color: #828693;
          font-weight: 500;
          font-size: 15px;
          min-width: 100px;
        }

        .right {
          text-align: right;
          font-size: 15px;
          min-width: 100px;

        }
      }
    }
  }

  .logo {
    background-image: url("@/assets/imgs/login-logo.svg");
    height: 106px;
    width: 197px;
    margin: 40px auto;
  }

  .description {
    padding: 5px 50px;
    font-size: 15px;
  }

  .email {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 20px 0 50px 0;

    .item {
      width: 80%;
      max-width: 800px;
      margin: auto;
    }
  }

  .elements {
    padding: 0 50px;
    max-width: 800px;
    display: flex;
    flex-direction: column;

    .price-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;

      .price {
        font-size: 25px;
        font-weight: 500;
      }

      .price-subtitle {
        font-size: 13px;
        margin: 0 5px;
      }
    }

    .button {
      margin: 20px 0;
    }

    #payment-element {
      margin: 20px 0;

      .country-field {
        display: none;
      }
    }
  }

  .contact{
    margin: 10px 0 40px 0;
    //font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
}

</style>
