<script>
import _ from 'lodash'
import global from '@/utils/global'
import { mapState } from 'vuex'
import { preparationApi, saveFormDataApi, continueApi } from '@/api/PoliceResultSharingConsent'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
export default {
  name: 'PoliceResultSharingConsent',
  inject: ['SaveFormDataSetSession', 'ContinueSetSession'],
  data() {
    return {
      currentFormSection: {},
      ruleForm: {
        consentToShare: null,
      },
      rules: {
        consentToShare: [{ required: true, message: 'Please select a value', trigger: 'blur, change' }],
      },
      allLoading: false,
      organisationName: ''
    }
  },
  computed: {
    ...mapState({
      device: state => state.setting.device,
      introduction: state => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    isMobile() {
      return this.device === 'mobile'
    },
    Session() {
      return this.preparation.session
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    ContinueBtn() {
    // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (!this.Session.current.isSubmitted ||
     this.Session.current.formSection.completePercent < 100 ||
     this.Session.current.isAdmin)
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'MedicalDeclaration')
      // }
      this.allLoading = true
      const params = {
        session: this.Session,
        ApplicantId: this.ApplicantId
      }
      await preparationApi(params).then(res => {
        const { data } = res
        this.currentFormSection = data.currentFormSection
        this.ruleForm.consentToShare = data.consentToShare
        this.organisationName = data.organisationName

        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
    },

    Continue(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          const params = {
            session: this.Session,
            applicantId: this.ApplicantId,
            currentFormSection: this.currentFormSection,
            consentToShare: this.ruleForm.consentToShare,
          }
          await continueApi(params).then(res => {
            this.ContinueSetSession(res.data.session)
          })
        }
      })
    },

    SaveFormTempData(formName) {
      _.debounce(
        this.$refs[formName].validate(async(valid) => {
          const params = {
            session: this.Session,
            applicantId: this.ApplicantId,
            consentToShare: this.ruleForm.consentToShare
          }
          saveFormDataApi(params).then(res => {
            if (res.data && res.data.session) {
              this.SaveFormDataSetSession(res.data.session)
            }
          })

        }), global.debounceTime)
    }
  }
}
</script>
<template>
  <div v-loading="allLoading" class="MedicalDeclarationView">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      :disabled="allDisabled"
    >
      <el-form-item
        prop="consentToShare"
      >
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="24" :lg="16"
            :xl="16" class="label"
          >
            <span class="color-red">*</span> Would you like Precise Background Services to share a copy of your Nationally Coordinate Criminal History Check result to <b>{{organisationName}}</b>, including any authorised third parties involved in the background check on
            your behalf?
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="24" :lg="8"
            :xl="8"
          >
            <el-radio-group v-model="ruleForm.consentToShare" @change="SaveFormTempData('ruleForm')">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <!-- <el-button type="primary" @click="SaveFormTempData('ruleForm')">Save form data</el-button> -->
      <el-button v-if="ContinueBtn" :loading="endAllRequests" type="primary" @click="Continue('ruleForm')">Save and Continue</el-button>
    </el-form>
  </div>
</template>
<style lang="scss" >
.MedicalDeclarationView {
  .label {
    line-height: 1.5;
    font-size: 14px;
    color: #606266;
  }
  .el-form-item__label {
    line-height: 18px;
  }
}
</style>
