import service from '@/utils/request'
const api = process.env.NODE_ENV !== 'development' ? '/api/applicant/police-result' : '/proxy/api/applicant/police-result'

export function preparationApi(data) {
  return service({
    url: `${api}/preparation`,
    method: 'post',
    data: data
  })
}

export function confirmApi(data) {
  return service({
    url: `${api}/confirm`,
    method: 'post',
    data: data
  })
}

export function autoCompleteApi(data) {
  return service({
    url: `${api}/auto-confirm`,
    method: 'post',
    data: data
  })
}
