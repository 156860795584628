<script>
import { mapState } from 'vuex'
import OnlyUpload from '@/components/OnlyUpload'
import { preparationApi, uploadCommencementApi, uploadOtherNameApi, uploadPrimaryApi,
  uploadSecondary1Api, uploadSecondary2Api, uploadSelfieApi, removeDocumentApi,
  onCommencementComboChangetApi, onOtherNameDocumentTypeChangetApi, onPrimaryComboChangetApi,
  onSecondary1ComboChangetApi, onSecondary2ComboChangetApi, otherNameRadioButtonOnChangeApi, continueApi } from '@/api/ProofOfIdentity'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
export default {
  name: 'ProofOfIdentity',
  components: { OnlyUpload },
  inject: ['ContinueSetSession', 'SaveFormDataSetSession'],
  data() {
    const validateCommencementOrderApplicantDocuments = (rule, value, callback) => {
      if (!this.commencementOrderApplicantDocuments.length) {
        callback(new Error('Please upload a commencement document before continuing'))
      } else {
        callback()
      }
    }
    const validatePrimaryOrderApplicantDocuments = (rule, value, callback) => {
      if (!this.primaryOrderApplicantDocuments.length) {
        callback(new Error('Please upload a primary document before continuing'))
      } else {
        callback()
      }
    }
    const validateSecondary1OrderApplicantDocuments = (rule, value, callback) => {
      if (!this.secondary1OrderApplicantDocuments.length) {
        callback(new Error('Please upload one more secondary document before continuing'))
      } else {
        callback()
      }
    }
    const validateSecondary2OrderApplicantDocuments = (rule, value, callback) => {
      if (!this.secondary2OrderApplicantDocuments.length) {
        callback(new Error('Please upload one more secondary document before continuing'))
      } else {
        callback()
      }
    }
    const validateOtherNameOrderApplicantDocuments = (rule, value, callback) => {
      if (!this.otherNameOrderApplicantDocuments.length) {
        callback(new Error('Please upload supporting documents for your name change before continuing'))
      } else {
        callback()
      }
    }
    const validateSelfieOrderApplicantDocuments = (rule, value, callback) => {
      if (!this.selfieOrderApplicantDocuments.length) {
        callback(new Error('Please upload one photo of yourself holding an above Identity Document containing photo ID before continuing'))
      } else {
        callback()
      }
    }
    const validateSelectedOtherNameDocumentType = (rule, value, callback) => {
      if (!this.ruleForm.selectedOtherNameDocumentType) {
        callback(new Error('Please upload supporting documents for your name change before continuing'))
      } else {
        callback()
      }
    }
    return {
      listFilterCommencement: [],
      listFilterPrimary: [],
      secondary1DocumentTypes: [],
      secondary2DocumentTypes: [],
      getOtherNamesByApplicantId: [],
      otherNameDocumentTypeId: null,

      commencementOrderApplicantDocuments: [],
      primaryOrderApplicantDocuments: [],
      secondary1OrderApplicantDocuments: [],
      secondary2OrderApplicantDocuments: [],
      selfieOrderApplicantDocuments: [],
      otherNameOrderApplicantDocuments: [],
      currentFormSection: {},
      changeOfNameCertDocumentTypeId: null,
      ausMarriageCertDocumentTypeId: null,

      ruleForm: {
        usesOtherName: '',
        selectedCommencementDocumentTypeId: null,
        selectedPrimaryDocumentTypeId: null,
        selectedSecondary1DocumentTypeId: null,
        selectedSecondary2DocumentTypeId: null
      },
      rules: {
        selectedCommencementDocumentTypeId: [{ required: true, message: 'Please upload a commencement document before continuing', trigger: 'change' }],
        selectedPrimaryDocumentTypeId: [{ required: true, message: 'Please upload a primary document before continuing', trigger: 'change' }],
        selectedSecondary1DocumentTypeId: [{ required: true, message: 'Please upload one more secondary document before continuing', trigger: 'change' }],
        selectedSecondary2DocumentTypeId: [{ required: true, message: 'Please upload one more secondary document before continuing', trigger: 'change' }],
        usesOtherName: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        selectedOtherNameDocumentType: [{ required: true, validator: validateSelectedOtherNameDocumentType, trigger: 'change' }],
        commencementOrderApplicantDocuments: [{ type: 'array', required: true, validator: validateCommencementOrderApplicantDocuments, trigger: 'change' }],
        primaryOrderApplicantDocuments: [{ type: 'array', required: true, validator: validatePrimaryOrderApplicantDocuments, trigger: 'change' }],
        secondary1OrderApplicantDocuments: [{ type: 'array', required: true, validator: validateSecondary1OrderApplicantDocuments, trigger: 'change' }],
        secondary2OrderApplicantDocuments: [{ type: 'array', required: true, validator: validateSecondary2OrderApplicantDocuments, trigger: 'change' }],
        otherNameOrderApplicantDocuments: [{ type: 'array', required: true, validator: validateOtherNameOrderApplicantDocuments, trigger: 'change' }],
        selfieOrderApplicantDocuments: [{ type: 'array', required: true, validator: validateSelfieOrderApplicantDocuments, trigger: 'change' }]

      },
      optionsCommencement: [],
      CommencementUploadState: false,
      PrimaryUploadState: false,
      secondary1UploadState: false,
      secondary2UploadState: false,
      hasOtherNameLoading: false,
      allLoading: false,
      selfieDocumentIntro: ''
    }
  },
  computed: {
    ...mapState({
      device: (state) => state.setting.device,
      introduction: state => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    isMobile() {
      return this.device === 'mobile'
    },
    Session() {
      return this.preparation.session
    },
    BlockName() {
      return this.preparation.session.current.formSection.blockName
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    CommencementUploadShow() {
      return this.ruleForm.selectedCommencementDocumentTypeId && this.CommencementUploadState
    },
    PrimaryUploadShow() {
      return this.ruleForm.selectedPrimaryDocumentTypeId && this.PrimaryUploadState
    },
    secondary1UploadShow() {
      return this.ruleForm.selectedSecondary1DocumentTypeId && this.secondary1UploadState
    },
    secondary2UploadShow() {
      return this.ruleForm.selectedSecondary2DocumentTypeId && this.secondary2UploadState
    },
    getOtherNamesByApplicantIdShow() {
      return this.getOtherNamesByApplicantId.length > 0
    },
    selectedOtherNameDocumentTypeDisabled() {
      return this.otherNameOrderApplicantDocuments.length > 0
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    ContinueBtn() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    buttonText() {
      // If(Session.Current.FormSection.Index < (Session.FormSections.Length - 1), "Save and Continue", "Submit")
      return this.Session.current.formSection.index <
        this.Session.formSections.length - 1
        ? 'Save and Continue'
        : 'Submit'
    },
    commencementDescription() {
      const data = this.listFilterCommencement.find(item => this.ruleForm.selectedCommencementDocumentTypeId === item.id)
      return data ? data.description : ''
    },
    primaryDescription() {
      const data = this.listFilterPrimary.find(item => this.ruleForm.selectedPrimaryDocumentTypeId === item.id)
      return data ? data.description : ''
    },
    secondary1Description() {
      const data = this.secondary1DocumentTypes.find(item => this.ruleForm.selectedSecondary1DocumentTypeId === item.id)
      return data ? data.description : ''
    },
    secondary2Description() {
      const data = this.secondary2DocumentTypes.find(item => this.ruleForm.selectedSecondary2DocumentTypeId === item.id)
      return data ? data.description : ''
    },
    secondary2DocumentTypesShow() {
      const list = JSON.parse(JSON.stringify(this.secondary2DocumentTypes))
      const spliceIndex = this.secondary1DocumentTypes.findIndex(item => this.ruleForm.selectedSecondary1DocumentTypeId === item.id)
      if (spliceIndex !== -1) {
        list.splice(spliceIndex, 1)
      }
      return list
    },
    secondary1DocumentTypesShow() {
      const list = JSON.parse(JSON.stringify(this.secondary1DocumentTypes))
      const spliceIndex = this.secondary2DocumentTypes.findIndex(item => this.ruleForm.selectedSecondary2DocumentTypeId === item.id)
      if (spliceIndex !== -1) {
        list.splice(spliceIndex, 1)
      }
      return list
    },
    imgcanDelete() {
      // (not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100) or Session.Current.IsAdmin
      return (!this.Session.current.isSubmitted || this.Session.current.formSection.completePercent < 100) || this.Session.current.isAdmin
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    getImgcanDelete(item) {
      // (OtherNameDocuments.List.Current.UploadedBlockName = Session.Current.FormSection.BlockName and
      //  (not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100)) or Session.Current.IsAdmin
      return (item.uploadedBlockName === this.Session.current.formSection.blockName &&
      (!this.Session.current.isSubmitted || this.Session.current.formSection.completePercent < 100)) || this.Session.current.isAdmin
    },
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'ProofOfIdentity')
      // }
      this.allLoading = true
      const params = {
        session: this.Session,
        ApplicantId: this.ApplicantId
      }
      await preparationApi(params).then(res => {
        const { data } = res
        this.ausMarriageCertDocumentTypeId = data.ausMarriageCertDocumentTypeId
        this.changeOfNameCertDocumentTypeId = data.changeOfNameCertDocumentTypeId
        this.listFilterCommencement = data.listFilterCommencement
        this.listFilterPrimary = data.listFilterPrimary
        this.secondary1DocumentTypes = data.secondary1DocumentTypes
        this.secondary2DocumentTypes = data.secondary2DocumentTypes
        this.getOtherNamesByApplicantId = data.getOtherNamesByApplicantId
        this.changeOfNameCertDocumentTypeId = data.changeOfNameCertDocumentTypeId
        this.ruleForm.selectedCommencementDocumentTypeId = data.selectedCommencementDocumentTypeId || null
        this.ruleForm.selectedPrimaryDocumentTypeId = data.selectedPrimaryDocumentTypeId || null
        this.ruleForm.selectedSecondary1DocumentTypeId = data.selectedSecondary1DocumentTypeId || null
        this.ruleForm.selectedSecondary2DocumentTypeId = data.selectedSecondary2DocumentTypeId || null
        this.otherNameDocumentTypeId = data.otherNameDocumentTypeId
        this.ruleForm.usesOtherName = data.usesOtherName
        if (data.selectedOtherNameDocumentType === 'ChangeOfName') {
          this.ruleForm.selectedOtherNameDocumentType = this.changeOfNameCertDocumentTypeId
        } else if (data.selectedOtherNameDocumentType === 'Marriage') {
          this.ruleForm.selectedOtherNameDocumentType = this.ausMarriageCertDocumentTypeId
        } else {
          this.ruleForm.selectedOtherNameDocumentType = null
        }
        this.commencementOrderApplicantDocuments = data.commencementOrderApplicantDocuments
        this.primaryOrderApplicantDocuments = data.primaryOrderApplicantDocuments
        this.secondary1OrderApplicantDocuments = data.secondary1OrderApplicantDocuments
        this.secondary2OrderApplicantDocuments = data.secondary2OrderApplicantDocuments
        this.selfieOrderApplicantDocuments = data.selfieOrderApplicantDocuments
        this.otherNameOrderApplicantDocuments = data.otherNameOrderApplicantDocuments
        this.currentFormSection = data.currentFormSection

        this.CommencementUploadState = !!this.ruleForm.selectedCommencementDocumentTypeId
        this.PrimaryUploadState = !!this.ruleForm.selectedPrimaryDocumentTypeId
        this.secondary1UploadState = !!this.ruleForm.selectedSecondary1DocumentTypeId
        this.secondary2UploadState = !!this.ruleForm.selectedSecondary2DocumentTypeId
        this.allLoading = false
        this.selfieDocumentIntro = data.selfieDocumentIntro
      }).catch(() => {
        this.allLoading = false
      })
    },

    async CloudinaryUpload_UploadResult(type, file) {
      const formData = new FormData()
      formData.append('SelectedPrimaryDocumentTypeId', this.ruleForm.selectedPrimaryDocumentTypeId || false)
      formData.append('SelectedCommencementDocumentTypeId', this.ruleForm.selectedCommencementDocumentTypeId || false)
      formData.append('OtherNameDocumentTypeId', this.ruleForm.selectedOtherNameDocumentType)
      formData.append('SelectedSecondary1DocumentTypeId', this.ruleForm.selectedSecondary1DocumentTypeId || false)
      formData.append('SelectedSecondary2DocumentTypeId', this.ruleForm.selectedSecondary2DocumentTypeId || false)

      formData.append('OrderApplicantId', this.Session.current.orderApplicantId)
      formData.append('BlockName', this.BlockName)
      formData.append('file', file)

      switch (type) {
        case 'Commencement':
          this.CloudinaryUpload_UploadResult_Commencement(formData)
          break
        case 'OtherName':
          this.CloudinaryUpload_UploadResult_OtherName(formData)
          break
        case 'Primary':
          this.CloudinaryUpload_UploadResult_Primary(formData)
          break
        case 'Secondary1':
          this.CloudinaryUpload_UploadResult_Secondary1(formData)
          break
        case 'Secondary2':
          this.CloudinaryUpload_UploadResult_Secondary2(formData)
          break
        case 'Selfie':
          this.CloudinaryUpload_UploadResult_Selfie(formData)
          break
        default:
          break
      }
    },
    async CloudinaryUpload_UploadResult_Commencement(formData) {
      await uploadCommencementApi(formData).then(res => {
        const { data } = res
        this.commencementOrderApplicantDocuments = data.commencementOrderApplicantDocuments
        this.$refs.ruleForm.validateField(['commencementOrderApplicantDocuments'])
      })
    },
    async CloudinaryUpload_UploadResult_OtherName(formData) {
      await uploadOtherNameApi(formData).then(res => {
        const { data } = res
        this.otherNameOrderApplicantDocuments = data.otherNameOrderApplicantDocuments
        this.$refs.ruleForm.validateField(['otherNameOrderApplicantDocuments'])
      })
    },
    async CloudinaryUpload_UploadResult_Primary(formData) {
      await uploadPrimaryApi(formData).then(res => {
        const { data } = res
        this.primaryOrderApplicantDocuments = data.primaryOrderApplicantDocuments
        this.$refs.ruleForm.validateField(['primaryOrderApplicantDocuments'])
      })
    },
    async CloudinaryUpload_UploadResult_Secondary1(formData) {
      await uploadSecondary1Api(formData).then(res => {
        const { data } = res
        this.secondary1OrderApplicantDocuments = data.secondary1OrderApplicantDocuments
        this.$refs.ruleForm.validateField(['secondary1OrderApplicantDocuments'])
      })
    },
    async CloudinaryUpload_UploadResult_Secondary2(formData) {
      await uploadSecondary2Api(formData).then(res => {
        const { data } = res
        this.secondary2OrderApplicantDocuments = data.secondary2OrderApplicantDocuments
        this.$refs.ruleForm.validateField(['secondary2OrderApplicantDocuments'])
      })
    },
    async CloudinaryUpload_UploadResult_Selfie(formData) {
      await uploadSelfieApi(formData).then(res => {
        const { data } = res
        this.selfieOrderApplicantDocuments = data.selfieOrderApplicantDocuments
        this.$refs.ruleForm.validateField(['selfieOrderApplicantDocuments'])
      })
    },

    async Document_Remove(name, index, item) {
      const params = {
        Session: this.Session,
        OrderApplicantDocumentId: item.id
      }
      await removeDocumentApi(params).then(res => {
        this[name].splice(index, 1)
        this.SaveFormDataSetSession(res.data.session)
      })
    },

    async OnCommencementComboChange(val) {
      this.CommencementUploadState = false
      if (!val) return
      const params = {
        DocumentTypeId: this.ruleForm.selectedCommencementDocumentTypeId,
        OrderApplicantId: this.Session.current.orderApplicantId
      }
      await onCommencementComboChangetApi(params).then(res => {
        const { data } = res
        this.CommencementUploadState = true
        this.commencementOrderApplicantDocuments = data.commencementOrderApplicantDocuments
      })
    },
    async OnOtherNameDocumentTypeChange(val) {
      this.otherNameDocumentTypeId = this.ruleForm.selectedOtherNameDocumentType
      const params = {
        DocumentTypeId: this.ruleForm.selectedOtherNameDocumentType,
        OrderApplicantId: this.Session.current.orderApplicantId
      }
      await onOtherNameDocumentTypeChangetApi(params).then(res => {
        const { data } = res
        this.otherNameOrderApplicantDocuments = data.otherNameDocumentTypeId
        this.$refs.ruleForm.validateField(['selectedOtherNameDocumentType'])
      })
      this.$forceUpdate()
    },
    async OnPrimaryComboChange(val) {
      this.PrimaryUploadState = false
      if (!val) return
      const params = {
        DocumentTypeId: this.ruleForm.selectedPrimaryDocumentTypeId,
        OrderApplicantId: this.Session.current.orderApplicantId
      }
      await onPrimaryComboChangetApi(params).then(res => {
        const { data } = res
        this.PrimaryUploadState = true
        this.primaryOrderApplicantDocuments = data.primaryOrderApplicantDocuments
      })
    },
    async OnSecondary1ComboChange(val) {
      this.secondary1UploadState = false
      if (!val) return
      const params = {
        DocumentTypeId: this.ruleForm.selectedSecondary1DocumentTypeId,
        OrderApplicantId: this.Session.current.orderApplicantId
      }
      await onSecondary1ComboChangetApi(params).then(res => {
        const { data } = res
        this.secondary1UploadState = true
        this.secondary1OrderApplicantDocuments = data.secondary1OrderApplicantDocuments
      })
    },
    async OnSecondary2ComboChange(val) {
      this.secondary2UploadState = false
      if (!val) return
      const params = {
        DocumentTypeId: this.ruleForm.selectedSecondary2DocumentTypeId,
        OrderApplicantId: this.Session.current.orderApplicantId
      }
      await onSecondary2ComboChangetApi(params).then(res => {
        const { data } = res
        this.secondary2UploadState = true
        this.secondary2OrderApplicantDocuments = data.secondary2OrderApplicantDocuments
      })
    },
    async OtherNameRadioButtonOnChange() {
      this.hasOtherNameLoading = true
      const params = {
        session: this.Session,
        blockName: this.BlockName,
        orderApplicantId: this.Session.current.orderApplicantId,
        usesOtherName: this.ruleForm.usesOtherName,
        otherNameOrderApplicantDocuments: this.otherNameOrderApplicantDocuments
      }
      if (this.ruleForm.usesOtherName === 'No') {
        await otherNameRadioButtonOnChangeApi(params).then(res => {
          const { data } = res
          this.otherNameOrderApplicantDocuments = data.otherNameOrderApplicantDocuments
          this.otherNameDocumentTypeId = data.otherNameDocumentTypeId || 0
          if (data.selectedOtherNameDocumentType === 'ChangeOfName') {
            this.ruleForm.selectedOtherNameDocumentType = this.changeOfNameCertDocumentTypeId
          } else if (data.selectedOtherNameDocumentType === 'Marriage') {
            this.ruleForm.selectedOtherNameDocumentType = this.ausMarriageCertDocumentTypeId
          } else {
            this.ruleForm.selectedOtherNameDocumentType = null
          }
        })
      }
      this.hasOtherNameLoading = false
    },

    async Continue(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          const params = {
            session: this.Session,
            currentFormSection: this.currentFormSection,
            UsesOtherName: this.ruleForm.usesOtherName,
            SelectedCommencementDocumentTypeId: this.ruleForm.selectedCommencementDocumentTypeId,
            SelectedPrimaryDocumentTypeId: this.ruleForm.selectedPrimaryDocumentTypeId,
            SelectedSecondary1DocumentTypeId: this.ruleForm.selectedSecondary1DocumentTypeId,
            SelectedSecondary2DocumentTypeId: this.ruleForm.selectedSecondary2DocumentTypeId,
            OtherNameDocumentTypeId: this.otherNameDocumentTypeId,
            commencementOrderApplicantDocuments: this.commencementOrderApplicantDocuments,
            primaryOrderApplicantDocuments: this.primaryOrderApplicantDocuments,
            secondary1OrderApplicantDocuments: this.secondary1OrderApplicantDocuments,
            secondary2OrderApplicantDocuments: this.secondary2OrderApplicantDocuments,
            selfieOrderApplicantDocuments: this.selfieOrderApplicantDocuments,
            OtherNameOrderApplicantDocuments: this.otherNameOrderApplicantDocuments
          }
          // this.$message.success('continue')

          await continueApi(params).then(res => {
            this.ContinueSetSession(res.data.session)
          })
        }
      })
    }
  }
}
</script>
<template>
  <div v-loading="allLoading" class="ProofOfIdentity">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      label-position="top"
      :disabled="allDisabled"
    >
      <div>
        <el-row :gutter="10">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item prop="selectedCommencementDocumentTypeId">
              <label class="label" style="font-size: 16px; font-weight: 600;">Commencement Document (One Required)
                <el-select
                  v-model="ruleForm.selectedCommencementDocumentTypeId"
                  popper-class="dropdownClass"
                  clearable style="width:100%" placeholder="Select a Commencement Document Type" @change="OnCommencementComboChange"
                >
                  <el-option
                    v-for="item in listFilterCommencement"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  />
                </el-select>
              </label>
            </el-form-item>
          </el-col>
        </el-row>
        <div v-if="CommencementUploadShow">
          <div style="font-size: 16px; font-weight: 600;">{{ commencementDescription }}</div>
          <el-form-item prop="commencementOrderApplicantDocuments">
            <OnlyUpload v-if="commencementOrderApplicantDocuments.length<1 && imgcanDelete" @Document_Upload="CloudinaryUpload_UploadResult('Commencement',$event)" />
            <OnlyUpload
              v-for="(item,index) in commencementOrderApplicantDocuments"
              :key="item.id"
              :img-item="item"
              :can-delete="imgcanDelete"
              @Document_Upload="CloudinaryUpload_UploadResult('Commencement',$event)"
              @Document_Remove="Document_Remove('commencementOrderApplicantDocuments',index,$event)"
            />
          </el-form-item>
        </div>
        <el-divider />
      </div>

      <div>
        <el-row :gutter="10">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item prop="selectedPrimaryDocumentTypeId">
              <label class="label" style="font-size: 16px; font-weight: 600;">Primary Document (One Required)
                <el-select
                  v-model="ruleForm.selectedPrimaryDocumentTypeId"
                  popper-class="dropdownClass" clearable style="width:100%" placeholder="Select a Primary Document Type"
                  @change="OnPrimaryComboChange"
                >
                  <el-option
                    v-for="item in listFilterPrimary"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  />
                </el-select>
              </label>
            </el-form-item>
          </el-col>
        </el-row>
        <div v-if="PrimaryUploadShow">
          <div style="font-size: 16px; font-weight: 600;">{{ primaryDescription }}</div>
          <el-form-item prop="primaryOrderApplicantDocuments">
            <OnlyUpload v-if="primaryOrderApplicantDocuments.length<1 && imgcanDelete" @Document_Upload="CloudinaryUpload_UploadResult('Primary',$event)" />
            <OnlyUpload
              v-for="(item,index) in primaryOrderApplicantDocuments"
              :key="item.id"
              :img-item="item"
              :can-delete="imgcanDelete"
              @Document_Upload="CloudinaryUpload_UploadResult('Primary',$event)"
              @Document_Remove="Document_Remove('primaryOrderApplicantDocuments',index,$event)"
            />
          </el-form-item>
        </div>
        <el-divider />
      </div>

      <div>
        <el-row :gutter="10">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item prop="selectedSecondary1DocumentTypeId">
              <label class="label" style="font-size: 16px; font-weight: 600;">Secondary Documents (One Required)
                <el-select
                  v-model="ruleForm.selectedSecondary1DocumentTypeId"
                  popper-class="dropdownClass" clearable style="width:100%" placeholder="Select a Secondary Document Type"
                  @change="OnSecondary1ComboChange"
                >
                  <el-option
                    v-for="item in secondary1DocumentTypesShow"
                    :key="item.value"
                    :label="item.label"
                    :value="item.id"
                  />
                </el-select>
              </label>
            </el-form-item>
            <div v-if="secondary1UploadShow">
              <div style="font-size: 16px; font-weight: 600;">{{ secondary1Description }}</div>
              <el-form-item prop="secondary1OrderApplicantDocuments">
                <OnlyUpload v-if="secondary1OrderApplicantDocuments.length<1 && imgcanDelete" :style="'width:'+(isMobile ? '100%':'100%')" @Document_Upload="CloudinaryUpload_UploadResult('Secondary1',$event)" />
                <OnlyUpload
                  v-for="(item,index) in secondary1OrderApplicantDocuments"
                  :key="item.id"
                  :style="'width:'+(isMobile ? '100%':'50%')"
                  :img-item="item"
                  :can-delete="imgcanDelete"
                  @Document_Upload="CloudinaryUpload_UploadResult('Secondary1',$event)"
                  @Document_Remove="Document_Remove('secondary1OrderApplicantDocuments',index,$event)"
                />
              </el-form-item>
            </div>

          </el-col>
          <el-col v-if="secondary2OrderApplicantDocuments.length !== 0"
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item prop="selectedSecondary2DocumentTypeId">
              <label class="label" style="font-size: 16px; font-weight: 600;">Secondary Documents (One Required) - Optional
                <el-select
                  v-model="ruleForm.selectedSecondary2DocumentTypeId"
                  popper-class="dropdownClass" clearable style="width:100%" placeholder="Select a Secondary Document Type"
                  @change="OnSecondary2ComboChange"
                >
                  <el-option
                    v-for="item in secondary2DocumentTypesShow"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  />
                </el-select>
              </label>
            </el-form-item>
            <div v-if="secondary2UploadShow">
              <div style="font-size: 16px; font-weight: 600;">{{ secondary2Description }}</div>
              <el-form-item prop="secondary2OrderApplicantDocuments">
                <OnlyUpload v-if="secondary2OrderApplicantDocuments.length<1 && imgcanDelete" :style="'width:'+(isMobile ? '100%':'100%')" @Document_Upload="CloudinaryUpload_UploadResult('Secondary2',$event)" />
                <OnlyUpload
                  v-for="(item,index) in secondary2OrderApplicantDocuments"
                  :key="item.id"
                  :style="'width:'+(isMobile ? '100%':'50%')"
                  :img-item="item"
                  :can-delete="imgcanDelete"
                  @Document_Upload="CloudinaryUpload_UploadResult('Secondary2',$event)"
                  @Document_Remove="Document_Remove('secondary2OrderApplicantDocuments',index,$event)"
                />
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-divider />
      </div>

      <div v-if="getOtherNamesByApplicantIdShow">
        <el-form-item prop="usesOtherName" label="Do ANY of the uploaded Identity Documents use your former name?">
          <el-radio-group v-model="ruleForm.usesOtherName" v-loading="hasOtherNameLoading" @change="OtherNameRadioButtonOnChange">
            <el-radio label="Yes" />
            <el-radio label="No" />
          </el-radio-group>
        </el-form-item>

        <div v-if="ruleForm.usesOtherName === 'Yes' " class="mb-20">
          <el-form-item prop="selectedOtherNameDocumentType">
            <span slot="label" style="line-height:1.5">Please provide either a Change of Name Certificate issued by the Australian Registry of Births, Deaths and Marriages or an Australian Marriage Certificate issued by a state or territory. Church or celebrant-issued certificates are not accepted.</span>
            <el-radio-group v-model="ruleForm.selectedOtherNameDocumentType" :disabled="selectedOtherNameDocumentTypeDisabled" @change="OnOtherNameDocumentTypeChange">
              <el-radio-button :label="changeOfNameCertDocumentTypeId">Change of Name Certificate</el-radio-button>
              <el-radio-button :label="ausMarriageCertDocumentTypeId">Australian Marriage Certificate</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <div v-if="ruleForm.selectedOtherNameDocumentType">
            <el-form-item prop="otherNameOrderApplicantDocuments">
              <OnlyUpload v-if="otherNameOrderApplicantDocuments.length<1" @Document_Upload="CloudinaryUpload_UploadResult('OtherName',$event)" />
              <OnlyUpload
                v-for="(item,index) in otherNameOrderApplicantDocuments"
                :key="item.id"
                :img-item="item"
                :can-delete="getImgcanDelete(item)"
                @Document_Upload="CloudinaryUpload_UploadResult('OtherName',$event)"
                @Document_Remove="Document_Remove('otherNameOrderApplicantDocuments',index,$event)"
              />
            </el-form-item>
          </div>

        </div>
      </div>

      <div>
        <el-form-item prop="selfieOrderApplicantDocuments">
          <p class="f-s-14 f-w-600 title">We also require you to upload a photo of yourself holding one of the above PHOTO identity documents (eg: passport or licence)</p>
        </el-form-item>
        <div class="mb-10 f-s-14" v-html="selfieDocumentIntro" />

        <OnlyUpload v-if="selfieOrderApplicantDocuments.length<1" @Document_Upload="CloudinaryUpload_UploadResult('Selfie',$event)" />
        <OnlyUpload
          v-for="(item,index) in selfieOrderApplicantDocuments"
          :key="item.id"
          :img-item="item"
          :can-delete="getImgcanDelete(item)"
          @Document_Upload="CloudinaryUpload_UploadResult('Selfie',$event)"
          @Document_Remove="Document_Remove('selfieOrderApplicantDocuments',index,$event)"
        />
      </div>
    </el-form>
    <el-button
      v-if="ContinueBtn"
      class="mt-30"
      :loading="endAllRequests"
      type="primary"
      @click="Continue('ruleForm')"
    >{{ buttonText }}</el-button>
  </div>
</template>
<style lang="scss" scoped>
.ProofOfIdentity{
  h4{
    margin: 0 0 10px;
  }
  .title{
    line-height: 1.5;
  }
}
.dropdownClass .el-select-dropdown__item{
    width: 90vw !important;
    max-width: 500px;
}

</style>
