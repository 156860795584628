var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ApplicationView", attrs: { lang: "en" } },
    [
      _vm.showMessage
        ? _c("div", {
            staticClass: "applicant-message",
            domProps: { innerHTML: _vm._s(_vm.showMessageText) },
          })
        : _vm._e(),
      _vm.isShow("Welcome") ? _c("Welcome") : _vm._e(),
      _vm.isShow("AuthorisedRepresentative")
        ? _c("AuthorisedRepresentative")
        : _vm._e(),
      _vm.isShow("ReopenNotes") ? _c("ReopenNotes") : _vm._e(),
      _vm.isShow("AddressHistory") ? _c("AddressHistory") : _vm._e(),
      _vm.isShow("OtherCountries") ? _c("OtherCountries") : _vm._e(),
      _vm.isShow("CoIDeclaration") ? _c("CoIDeclaration") : _vm._e(),
      _vm.isShow("CoIDeclarationNAB") ? _c("CoIDeclarationNAB") : _vm._e(),
      _vm.isShow("RequiredDeclarationsNAB")
        ? _c("RequiredDeclarationsNAB")
        : _vm._e(),
      _vm.isShow("EYQuestion") ? _c("EYQuestion") : _vm._e(),
      _vm.isShow("EYQuestionG1_3") ? _c("EYQuestionG1_3") : _vm._e(),
      _vm.isShow("EYQuestionG4") ? _c("EYQuestionG4") : _vm._e(),
      _vm.isShow("CVUpload") ? _c("CVUpload") : _vm._e(),
      _vm.isShow("ComplianceReference") ? _c("ComplianceReference") : _vm._e(),
      _vm.isShow("ElectronicLodgement") ? _c("ElectronicLodgement") : _vm._e(),
      _vm.isShow("EmploymentHistory") ? _c("EmploymentHistory") : _vm._e(),
      _vm.isShow("EmploymentReference") ? _c("EmploymentReference") : _vm._e(),
      _vm.isShow("EntitlementToWork") ? _c("EntitlementToWork") : _vm._e(),
      _vm.isShow("EntitlementToWork2") ? _c("EntitlementToWork2") : _vm._e(),
      _vm.isShow("Identity100Points") ? _c("Identity100Points") : _vm._e(),
      _vm.isShow("ManagementDirector") ? _c("ManagementDirector") : _vm._e(),
      _vm.isShow("MedicalDeclaration") ? _c("MedicalDeclaration") : _vm._e(),
      _vm.isShow("NotImplemented") ? _c("NotImplemented") : _vm._e(),
      _vm.isShow("PersonalDetails") ? _c("PersonalDetails") : _vm._e(),
      _vm.isShow("PersonalDetailsInternational")
        ? _c("PersonalDetailsInternational")
        : _vm._e(),
      _vm.isShow("ProfessionalMemberships")
        ? _c("ProfessionalMemberships")
        : _vm._e(),
      _vm.isShow("ProofOfIdentity") ? _c("ProofOfIdentity") : _vm._e(),
      _vm.isShow("QualificationsNAB") ? _c("QualificationsNAB") : _vm._e(),
      _vm.isShow("QualificationsTertiary")
        ? _c("QualificationsTertiary")
        : _vm._e(),
      _vm.isShow("QualificationsTrade") ? _c("QualificationsTrade") : _vm._e(),
      _vm.isShow("RegisteredTaxAgent") ? _c("RegisteredTaxAgent") : _vm._e(),
      _vm.isShow("VulnerablePeople") ? _c("VulnerablePeople") : _vm._e(),
      _vm.isShow("Confirmation") ? _c("Confirmation") : _vm._e(),
      _vm.isShow("AdobeSign") ? _c("AdobeSign") : _vm._e(),
      _vm.isShow("ThankYou") ? _c("ThankYou") : _vm._e(),
      _vm.isShow("Covid19Vaccination") ? _c("Covid19Vaccination") : _vm._e(),
      _vm.isShow("PoliceResultSharingConsent")
        ? _c("PoliceResultSharingConsent")
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }