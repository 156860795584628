import moment from 'moment-timezone'

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
    g: date.getMonth()
  }
  const time_str = format.replace(/{([ymdhisag])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (key === 'g') {
      return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

export function parseDefaultTime(time, cFormat) {
  if (!time) {
    return ''
  }
  if (time.includes('1900-01-01')) {
    return ''
  } else {
    time = Date.parse(time)
    return parseTime(time, cFormat)
  }
}

export function textEllipsis(str) {
  let curStr = ''
  if (str) {
    if (str.length > 60) {
      curStr = str.substring(0, 60) + '...'
    } else {
      curStr = str
    }
  }
  return curStr
}

/**
 * Is current Date
 * @param {(string|number)} data
 * @param {(string)} type
 * @returns {Boolean}
 */
export function isNullDate(data, type) {
  if (type === '<>') {
    if (data != null && !data.includes('1900-01-01') && data !== '' && data !== undefined) {
      return true
    } else {
      return false
    }
  }
  if (type === '=') {
    if (data == null || data.includes('1900-01-01') || data === '' || data === undefined) {
      return true
    } else {
      return false
    }
  }
}

/**
 * Is NullIdentifier
 * @param {(string|number)} data
 * @param {(string)} type
 * @returns {Boolean}
 */
export function isNullIdentifier(data, type) {
  if (type === '<>') {
    if (data != null && data !== '') {
      return true
    } else {
      return false
    }
  }
}

/**
 * Is repeat
 * @param {(Array)} arr
 * @returns {Array}
 */
export function isRepeat(arr) {
  var hash = {}
  var lastIndex = 0
  for (let i = 0; i < arr.length; i++) {
    if (!hash[arr[i].index]) {
      hash[arr[i].index] = true
    }
  }
  if (Object.keys(hash).length < arr.length) {
    for (let i = 0; i < arr.length; i++) {
      arr[i].index = lastIndex++
    }
  }
  return arr
}

export function convertUTC2LocalDateTime(time) {
  return moment.utc(time).local().format('DD MMM YYYY HH:mm:ss')
}
