import router from '@/router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import {
  getToken
} from '@/utils/auth' // get token from cookie

NProgress.configure({
  showSpinner: false
}) // NProgress Configuration

const whiteList = ['/login', '/token-login', '/register', '/sessionExpired', '/payment', '/payment-thank-you']

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()
  document.title = 'Applicant Portal'

  // set page title
  // document.title = getPageTitle(to.meta.title)
  const hasToken = getToken()
  // console.log('hasToken--------------------', hasToken)
  if (hasToken) {
    if (to.fullPath.includes('/Home.aspx')) {
      next(`/application`)
      NProgress.done()
    } else if (to.fullPath.includes('/Check.aspx')) {
      next(`/login?id=${to.query.id}`)
      NProgress.done()
    } else if (to.fullPath.includes('/OTPLogin.aspx')) {
      next(`/login?un=${to.query.un}`)
      NProgress.done()
    } else {
      next()
      NProgress.done()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
      NProgress.done()
    } else if (to.fullPath.includes('/Check.aspx')) {
      next(`/login?id=${to.query.id}`)
      NProgress.done()
    } else if (to.fullPath.includes('/OTPLogin.aspx')) {
      next(`/login?un=${to.query.un}`)
      NProgress.done()
    } else if (to.fullPath.includes('/Home.aspx')) {
      next(`/login`)
      NProgress.done()
    } else {
      next(`/login`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
