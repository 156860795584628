<script>
import Header from './components/Header/index.vue'
import Sider from './components/Sider/index.vue'
import ResizeMixin from './mixin/ResizeHandler'
import Application from '@/views/Edit/application.vue'
import { mapState } from 'vuex'
import moment from 'moment-timezone'
export default {
  name: 'LayoutView',
  components: { Header, Sider, Application },
  mixins: [ResizeMixin],
  provide() {
    return {
      closeNav: this.closeNav
    }
  },
  data() {
    return {
      showNav: false,
      Intro: ''
    }
  },
  computed: {
    ...mapState({
      device: (state) => state.setting.device,
      session_timeout: (state) => state.setting.session_timeout,
      ReferenceNumber: (state) => state.preparation.ReferenceNumber,
      section: (state) => state.section.section,
      preparation: (state) => state.preparation.preparation
    }),
    classObj() {
      return {
        mobile: this.device === 'mobile'
      }
    },
    sessionVisible() {
      return this.session_timeout
    },
    Session() {
      return this.preparation.session
    },
    goSelect() {
      return this.preparation.goSelect || false
    },
    PreviousShow() {
      if (this.Session && this.Session.formSections.length > 0) {
        const blockName = this.Session.formSections[0].blockName || ''
        if (!this.goSelect && this.section === blockName) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    NextShow() {
      if (this.Session && this.Session.formSections.length > 0) {
        const index = this.Session.formSections.length - 1
        const blockName = this.Session.formSections[index].blockName || ''
        if (!this.goSelect && this.section === blockName) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    currentYear() {
      return moment.tz().year()
    }
  },
  methods: {
    navMobile(val) {
      this.showNav = val
    },

    closeNav() {
      this.showNav = false
    },
    Previous() {
      this.$refs.application.BackNext({
        session: this.Session,
        addToIndex: -1
      })
    },
    Next() {
      this.$refs.application.BackNext({ session: this.Session, addToIndex: 1 })
    },
    handleSession(state) {
      if (state) { // still here
        this.$store.dispatch('setting/set_session_timeout', false)
      } else { // log out
        this.$router.push({ name: 'Login' })
        this.$store.dispatch('user/logOut')
      }
    }
  }
}
</script>

<template>
  <div :class="classObj" class="app-wrapper">
    <Header class="app-header" @navMobile="navMobile" />
    <div class="app-main">
      <section>
        <el-row type="flex" :gutter="20" justify="center">
          <el-col
            :xs="24" :sm="24" :md="24" :lg="18"
            :xl="13"
          >
            <div class="container-application">
              <h1 class="title">Background Check</h1>
              <h4 v-if="device === 'mobile'" class="ref">PBS Ref# {{ ReferenceNumber }}</h4>
              <Application ref="application" />
              <div v-if="device === 'mobile'" class="Previous-Next">
                <span v-if="PreviousShow" class="Previous" @click="Previous">
                  <i class="fa fa-arrow-circle-left" /> <span class="f-s-14">Previous</span></span>
                <span v-if="NextShow" class="Next" @click="Next"><span class="f-s-14">Next</span> <i class="fa fa-arrow-circle-right" /></span>
              </div>
            </div>
          </el-col>
          <!-- <el-col
            :xs="0" :sm="6" :md="6" :lg="4"
            :xl="4"
          > -->
          <el-col
            v-if="device !== 'mobile'"
            style="width:300px"
          >
            <div class="container-nav">
              <span style="font-weight: bold; text-align: right;" class="ref">PBS Ref# {{ ReferenceNumber }}</span>
              <div>
                <Sider />
              </div>
              <div class="Previous-Next">
                <span v-if="PreviousShow" class="Previous" @click="Previous">
                  <i class="fa fa-arrow-circle-left" /> <span class="f-s-14">Previous</span></span>
                <span v-if="NextShow" class="Next" @click="Next"><span class="f-s-14">Next</span> <i class="fa fa-arrow-circle-right" /></span>
              </div>
            </div>
          </el-col>
        </el-row>
      </section>
      <footer class="app-footer">
        Copyright {{ this.currentYear }} PreciseBackground.com. All rights reserved.
      </footer>
    </div>

    <div :class="showNav ? 'block' : 'none'">
      <div class="nav-box">
        <div class="nav">
          <Sider />
        </div>
        <div class="blank" @click="closeNav" />
      </div>
    </div>

    <el-dialog
      :width="device === 'mobile'?'90%':'500px'"
      :visible.sync="sessionVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div slot="title">
        <div style="font-weight: bold; font-size: 18px; margin: 20px;">Are you still there?</div>
      </div>
      <div class="el-dialog__body">
        <p>Your session is about to expire due to inactivity.</p>
        <p>If you do not respond to this prompt within one minute you will be logged out.  </p>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button :class="device === 'mobile'?'width100':'width48'" type="primary" @click="handleSession(true)">I'm still here</el-button>
        <el-button :class="device === 'mobile'?'width100':'width48'" @click="handleSession(false)">Log me out now</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<style lang="scss" scoped>
::v-deep .el-dialog__body{
  padding:0 20px 10px;
  p{
    margin:0;
  }
}
.width48{
  width:48%;
}
.width100{
  width:100%;
  display: block;
  margin:0 0 10px 0 !important;
}
.block {
  display: block !important;
}
.none {
  display: none;
}
.app-wrapper {
  width: 100%;
  background-color: #ededed;
  position: relative;
  .app-header {
    position: fixed;
    z-index: 9999;
  }
  .app-main {
    background-color: #ededed;
    padding: 130px 10px 0;
    // width: 70%;
    margin:0 auto;
    .container-application{
      .title{
        font-size: 30px;
        margin: 0;
        height: 80px;
        line-height: 80px;
      }
    }
    .container-nav{
        .ref{
          margin:0;
          height: 80px;
          line-height: 80px;
          text-align: right;
        }
      }

    .Previous-Next {
          margin: 20px 0 0;
          color: #206caa;
          .Previous {
            float: left;
          }
          .Next {
            float: right;
          }
          span {
            cursor: pointer;
          }
        }
  }

  .app-footer {
    width:100%;
    text-align: center;
    font-size: 14px;
    margin: 200px auto 10px;
    padding-bottom: 10px;
  }
}
.mobile {
  .app-main {
    // padding: 120px 20px 0 20px;
    width:95%;
    margin:0 auto;
  }
  .nav-box {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    display: flex;
    .nav {
      overflow: auto;
      width: 70%;
      background-color: #fff;
      padding: 30px 0;
    }
    .blank {
      width: 30%;
      background-color: gray;
      opacity: 0.5;
    }
  }
  .app-footer {
    width:100%;
    text-align: center;
    font-size: 14px;
    margin: 40px auto 10px;
  }
}
</style>
