<script>
import { loginApi, loginPreparationApi } from '@/api/user'
import { Encrypt } from '@/utils/crypro'
import { showMessageApi } from '@/api/application'
export default {
  name: 'LoginView',
  data() {
    return {
      ruleForm: {
        email: ''
      },
      loginForm: {
        email: '',
        userId: 0,
        password: ''
      },
      status: 0,
      ContinueLoading: false,
      LoginLoading: false,
      alertText: 'We sent you a new password.',
      introduction: '',
      urlArr: ['originalURL', 'un', 'id', 'cr'],
      idLoginLoading: false,
      showMessage: false,
      showMessageText: ''
    }
  },
  created() {
    const hash = location.href
    const p = hash.split('?')[1]
    if (p) {
      const arr = new URLSearchParams(p)
      if (arr.get('un') || arr.get('id') || arr.get('cr')) {
        this.status = 1
      }
    }
    showMessageApi().then(async res => {
      const { data } = res
      this.showMessage = data.showMessage
      this.showMessageText = data.showMessageText
    })
  },
  methods: {
    async ContinueOTP() {
      const hash = location.href
      const p = hash.split('?')[1]
      const arr = new URLSearchParams(p)
      const params = {
        originalURL: arr.get('originalURL') || '',
        un: arr.get('un') || '',
        id: arr.get('id') || '',
        cr: arr.get('cr') || '',
        userId: null
      }
      await this.Preparation(params)
    },
    async Preparation(params) {
      console.log(params)
      console.log(this.status)
      this.idLoginLoading = true
      await loginPreparationApi(params).then((res) => {
        this.idLoginLoading = false
        const { data } = res
        if (data && data.isPasswordStep) {
          this.loginForm.userId = data.applicantUserId
          this.loginForm.email = data.emailAddress
          this.alertText = data.instructions.replace(/\/n/g, '<br />')
          this.status = 2
        } else {
          data && data.alertText && this.$message.warning(data.alertText)
          this.$router.push({
            name: 'Register',
            params: {
              emailAddress: params.un
            }
          })
        }
      }).catch(() => {
        this.idLoginLoading = false
      })
    },
    Continue() {
      this.$refs.loginForm.validate(async(valid) => {
        if (valid) {
          this.ContinueLoading = true
          const tempData = { ...this.ruleForm }
          tempData.password = Encrypt(this.ruleForm.password)
          await loginApi(tempData)
            .then((res) => {
              this.ContinueLoading = false
              const { data } = res
              if (data.isRegisterStep) {
                this.$router.push({
                  name: 'Register',
                  params: {
                    emailAddress: data.emailAddress
                  }
                })
              } else {
                this.loginForm.userId = data.applicantUserId
                this.loginForm.email = this.ruleForm.email
                this.alertText = data.alertText.replace(/\/n/g, '<br />')
                this.introduction = data.instruction.replace(/\/n/g, '<br />')
                this.status = 2
              }
            })
            .catch(() => {
              this.ContinueLoading = false
            })
        }
      })
    },
    LogIn() {
      this.$refs.passwordForm.validate(async(valid) => {
        if (valid) {
          this.LoginLoading = true
          const tempData = { ...this.loginForm }
          tempData.password = Encrypt(this.loginForm.password)
          this.$store
            .dispatch('user/login', tempData)
            .then((res) => {
              this.LoginLoading = false
              this.$store.dispatch('setting/set_session_timeout', false)
              this.$router.push({ path: '/application' })
            })
            .catch(() => {
              this.LoginLoading = false
            })
        }
      })
    }
  }
}
</script>
<template>
  <div v-loading="idLoginLoading" class="LoginView">
    <el-row type="flex" class="row-bg" justify="center">
      <el-col
        :xs="24" :sm="24" :md="24" :lg="24"
        :xl="24"
      ><div class="content-box">
        <div class="logo" />
        <h2 v-if="status!==1" class="text-c">Applicant Portal</h2>

        <div v-if="status===0">
          <h3 class="text-c">Enter your email to get started...</h3>
          <el-form
            ref="loginForm"
            :model="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
            label-position="top"
            :hide-required-asterisk="true"
            @submit.native.prevent
          >
            <el-form-item
              prop="email"
              label="Email Address"
              :rules="[
                {
                  required: true,
                  message: 'Email is required',
                  trigger: 'blur',
                },
                {
                  type: 'email',
                  message: 'Email is not valid!',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                v-model="ruleForm.email"
                :onkeyup="ruleForm.email = ruleForm.email.replace(/\s+/g,'')"
                @keyup.enter.native="Continue"
              />
            </el-form-item>
          </el-form>
          <el-button
            style="width: 100%"
            :loading="ContinueLoading"
            type="primary"
            @click="Continue"
          >Continue</el-button>
          <h5>Note: Your employers security settings may prevent normal access - you may need to use a personal device to complete online form.</h5>
        </div>

        <div v-if="status===1">
          <div class="welcomeText">
            <h3>Welcome to Precise Background Services Applicant Portal</h3>
            <h4>By clicking 'Continue' below, we will assist you in logging into our system.</h4>
            <el-button
              style="width: 100%"
              :loading="LoginLoading"
              type="primary"
              @click="ContinueOTP"
            >Continue</el-button>
            <h5>Note: Your employers security settings may prevent normal access - you may need to use a personal device to complete online form.</h5>
          </div>
        </div>

        <div v-if="status===2">
          <div class="tag">
            <i class="el-icon-info" />
            <span style="word-break: break-word;" v-html="alertText" />
          </div>
          <h3 class="text-c" style="font-size:16px" v-html="introduction" />
          <el-form
            ref="passwordForm"
            :model="loginForm"
            label-width="100px"
            class="demo-ruleForm"
            label-position="top"
            :hide-required-asterisk="true"
            @submit.native.prevent
          >
            <el-form-item
              prop="password"
              label="Password"
              :rules="[
                {
                  required: true,
                  message: 'Password is required',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                v-model="loginForm.password"
                type="password"
                @keyup.enter.native="LogIn"
              />
            </el-form-item>
          </el-form>
          <el-button
            style="width: 100%"
            :loading="LoginLoading"
            type="primary"
            @click="LogIn"
          >Log In</el-button>
        </div>

        <div v-if="showMessage" class="message-box">
          <div type="warning" class="applicant-message" v-html="showMessageText" />
        </div>
      </div>
      </el-col>
    </el-row>
  </div>
</template>
<style lang="scss" scoped>
.LoginView {
  height: 100%;
  background-color: #206baa;
  display: flex;
  justify-content: center;
  .message-box {
    padding: 20px 40px;
    background: #c44f47 !important;
    color: white;
    border-radius: 6px;
    margin: 20px 0;
    font-size: 15px;
    text-align: center;
  }
  .content-box {
    max-width: 600px;
    padding: 48px;
    background-color: #fff;
    border-radius: 6px;
    margin: 160px 20px 20px 20px;
    color: #4f575e;
    .logo {
      background-image: url("@/assets/imgs/login-logo.svg");
      height: 106px;
      width: 197px;
      margin: 0 auto;
    }
    .tag {
      width: 100%;
      color: #fff;
      background-color: #007aad;
      padding:10px 10px;
      box-sizing: border-box;
      border-radius: 4px;
      display: flex;
      align-items: center;
      i {
        font-size: 18px;
        display: block;
      }
      span {
        margin: 10px;
        font-size: 16px;
        display: block;
        word-break:break-all;
      }
    }
  }
  .welcomeText {
    padding: 10px 10px;
    font-size: 16px;
    text-align: center;
  }
}
</style>
