<script>
import { mapState } from 'vuex'
import { policeResultSharingConsent } from '@/api/pattern'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
export default {
  name: 'PoliceResultSharingConsent',
  props: {
    applicantId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      ruleForm: {
        consentToShare: null
      },
      allLoading: false
    }
  },
  computed: {
    ...mapState({
      device: (state) => state.setting.device
    }),
    isMobile() {
      return this.device === 'mobile'
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'MedicalDeclaration')
      // }
      if (this.applicantId !== null && this.applicantId !== undefined) {
        this.allLoading = true
        await policeResultSharingConsent({ applicantId: this.applicantId })
          .then((res) => {
            const { data } = res
            this.ruleForm.consentToShare = data.consentToShare
            this.ruleForm.organisationName = data.organisationName
            this.allLoading = false
          })
          .catch(() => {
            this.allLoading = false
          })
      }
    }
  }
}
</script>
<template>
  <div v-loading="allLoading" class="MedicalDeclarationView">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      class="demo-ruleForm"
    >
      <el-form-item prop="consentToShare">
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="24" :lg="16"
            :xl="16" class="label"
          >
            <span class="color-red">*</span> Would you like Precise Background Services to share a copy of your Nationally Coordinate Criminal History Check result to <b>{{ this.ruleForm.organisationName }}</b>,
            including any authorised third parties involved in the background
            check on your behalf?
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="24" :lg="8"
            :xl="8"
          >
            <el-radio-group v-model="ruleForm.consentToShare" disabled>
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>
<style lang="scss">
.MedicalDeclarationView {
  .label {
    line-height: 1.5;
    font-size: 14px;
    color: #606266;
  }

  .el-form-item__label {
    line-height: 18px;
  }
}
</style>
