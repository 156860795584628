var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "thank-you" }, [
    _c(
      "span",
      [
        _c(
          "el-row",
          { staticClass: "row-bg", attrs: { type: "flex", justify: "center" } },
          [
            _c(
              "el-col",
              { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
              [
                _c("div", { staticClass: "container" }, [
                  _c("div", { staticClass: "logo" }),
                  _c("div", { staticClass: "title" }, [_vm._v(" Thank you! ")]),
                  _c("div", { staticClass: "description" }, [
                    _vm._v(
                      " You’ll soon get an email with your tax receipt and another asking you to activate your PBS Portal account. "
                    ),
                  ]),
                  _c("div", { staticClass: "description" }, [
                    _vm._v(
                      " Check your junk mailbox if you can’t find our email. "
                    ),
                  ]),
                  _c("div", { staticClass: "description" }, [
                    _vm._v(
                      " If you haven’t received these emails after 24 hours, contact us to have them resent to you. "
                    ),
                  ]),
                ]),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }