<script>
import { preparationApi } from '@/api/Select'
import { mapState } from 'vuex'
import PoliceReportDialog from '@/views/Select/Component/PoliceReportDialog.vue'
export default {
  name: 'SelectView',
  components: { PoliceReportDialog },
  data() {
    return {
      openOrderApplicants: [],
      closedOrderApplicants: [],
      reportLoading: false
    }
  },
  computed: {
    ...mapState({
      device: (state) => state.setting.device
    }),
    Session() {
      return this.$getPreparation().session
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      await preparationApi().then(res => {
        const { data } = res
        this.openOrderApplicants = data.openOrderApplicants
        this.closedOrderApplicants = data.closedOrderApplicants
        this.$store.dispatch('section/reset_section')
      })
    },
    async start(item) {
      const params = { session: this.Session }
      params.session.current.orderApplicantId = item.orderApplicantId
      params.session.current.referenceNumber = item.referenceNumber
      params.session.current.isSubmitted = item.isSubmitted
      params.session.current.applicantId = 0
      params.session.current.isReopened = false
      params.session.current.isConfirmed = false
      params.session.current.formSection.sectionId = 0
      params.session.current.formDataGUID = item.formDataGuid

      this.$router.push({
        name: 'application',
        params: {
          data: params
        }})
    },

    // async generateReportToken(item) {
    //   let token = ''
    //   const params = {
    //     OrganisationId: item.organisationId,
    //     ApplicantId: item.applicantId,
    //     OrderApplicantId: item.orderApplicantId
    //   }
    //   const { data } = await generateRepoortTokenApi(params)
    //   token = data.token
    //   return token
    // },
    // async ViewReport(item) {
    //   this.reportLoading = true
    //   let token = null
    //   await this.generateReportToken(item).then((res) => {
    //     token = res
    //     this.downloadPDF(token, item.referenceNumber)
    //   }).catch(() => {
    //     this.reportLoading = false
    //   })
    //   // window.open(`${process.env.VUE_APP_BASE_API}/api/pbs-report/police-report?token=${token}`, '_blank')
    // },
    //
    // downloadPDF(token, referenceNumber) {
    //   const url = `${process.env.VUE_APP_BASE_API}/api/pbs-report/police-report?token=${token}`
    //   const filename = `${referenceNumber}_Report.pdf`
    //   return fetch(url).then(res => res.blob().then(blob => {
    //     const a = document.createElement('a')
    //     const url = window.URL.createObjectURL(blob)
    //     a.href = url
    //     a.download = filename
    //     a.click()
    //     window.URL.revokeObjectURL(url)
    //     this.reportLoading = false
    //   }))
    // }

  }
}
</script>
<template>
  <div class="SelectView">
    <h3>Your Background Check Applications</h3>
    <p>We found the following Background Check Applications for you.</p>
    <el-divider />
    <div>
      <div v-for="item in openOrderApplicants" :key="item.id" class="order-box">
        <div class="text-l">
          <p class="f-w-600">PBS Ref# {{ item.referenceNumber }}</p>
          <p>{{ item.stage }}</p>
        </div>
        <div class="text-l">
          <p class="f-w-600">{{ item.jobPosition }}</p>
          <p>{{ item.organisationName }}</p>
        </div>
        <div
          :class="[
            'text-r',
            device==='mobile'?'':'primary-button',
          ]"
          style="display: flex; justify-content: flex-end;"
        >
          <PoliceReportDialog
            v-if="item.shouldShowPoliceResultPanel"
            :applicant-id="item.applicantId"
            :reference-number="item.referenceNumber"
            :order-applicant-id="item.orderApplicantId"
            :organisation-id="item.organisationId"
          />
          <el-button
            :class="[
              device==='mobile'?'':'primary-button',
            ]"
            type="secondary"
            @click="start(item)"
          >{{ item.buttonText }}</el-button>
        </div>
      </div>

      <div v-for="item in closedOrderApplicants" :key="item.id" class="order-box">
        <div class="text-l">
          <p class="f-w-600">PBS Ref# {{ item.referenceNumber }}</p>
          <p>{{ item.stage }}</p>
        </div>
        <div class="text-l">
          <p class="f-w-600">{{ item.jobPosition }}</p>
          <p>{{ item.organisationName }}</p>
        </div>
        <div
          :class="[
            'text-r',
            device==='mobile'?'width40':'',
          ]"
          style="display: flex; justify-content: flex-end;"
        >
          <PoliceReportDialog
            v-if="item.shouldShowPoliceResultPanel"
            :applicant-id="item.applicantId"
            :reference-number="item.referenceNumber"
            :order-applicant-id="item.orderApplicantId"
            :organisation-id="item.organisationId"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.SelectView{
  background-color: #fff;
  padding: 20px;
  .order-box{
    border: 1px solid rgb(225, 227, 232);
    background-color: rgb(249, 249, 250);
    padding: 30px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    div {
      width: 33%;
    }
    .width40{
      width: 40%;
    }
    p{
      margin: 0;
    }
    .primary-button{
      //width: 70%;
    }
    .color-green{
      background: #37b24d !important;
      border-color:#37b24d;
    }
    button{
      margin: 2px 2px;
      width: 130px;
    }
  }
}
@media screen and (max-width: 600px) {
  .SelectView{
    .order-box {
      flex-direction: column;
      div {
        width: 90%;
      }
      .text-l{
        display: flex;
        flex-direction: row !important;
        p {
          margin: 3px 3px;
          width: 100%;
        }
      }
      .text-r{
        margin: 10px 0;
        text-align: center !important;
      }
    }
  }
}
</style>
