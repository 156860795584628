var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.idLoginLoading,
          expression: "idLoginLoading",
        },
      ],
      staticClass: "LoginView",
    },
    [
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { type: "flex", justify: "center" } },
        [
          _c("el-col", { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } }, [
            _c("div", { staticClass: "content-box" }, [
              _c("div", { staticClass: "logo" }),
              _vm.status !== 1
                ? _c("h2", { staticClass: "text-c" }, [
                    _vm._v("Applicant Portal"),
                  ])
                : _vm._e(),
              _vm.status === 0
                ? _c(
                    "div",
                    [
                      _c("h3", { staticClass: "text-c" }, [
                        _vm._v("Enter your email to get started..."),
                      ]),
                      _c(
                        "el-form",
                        {
                          ref: "loginForm",
                          staticClass: "demo-ruleForm",
                          attrs: {
                            model: _vm.ruleForm,
                            "label-width": "100px",
                            "label-position": "top",
                            "hide-required-asterisk": true,
                          },
                          nativeOn: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "email",
                                label: "Email Address",
                                rules: [
                                  {
                                    required: true,
                                    message: "Email is required",
                                    trigger: "blur",
                                  },
                                  {
                                    type: "email",
                                    message: "Email is not valid!",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  onkeyup: (_vm.ruleForm.email =
                                    _vm.ruleForm.email.replace(/\s+/g, "")),
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.Continue.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.ruleForm.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "email", $$v)
                                  },
                                  expression: "ruleForm.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            loading: _vm.ContinueLoading,
                            type: "primary",
                          },
                          on: { click: _vm.Continue },
                        },
                        [_vm._v("Continue")]
                      ),
                      _c("h5", [
                        _vm._v(
                          "Note: Your employers security settings may prevent normal access - you may need to use a personal device to complete online form."
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.status === 1
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "welcomeText" },
                      [
                        _c("h3", [
                          _vm._v(
                            "Welcome to Precise Background Services Applicant Portal"
                          ),
                        ]),
                        _c("h4", [
                          _vm._v(
                            "By clicking 'Continue' below, we will assist you in logging into our system."
                          ),
                        ]),
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              loading: _vm.LoginLoading,
                              type: "primary",
                            },
                            on: { click: _vm.ContinueOTP },
                          },
                          [_vm._v("Continue")]
                        ),
                        _c("h5", [
                          _vm._v(
                            "Note: Your employers security settings may prevent normal access - you may need to use a personal device to complete online form."
                          ),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.status === 2
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "tag" }, [
                        _c("i", { staticClass: "el-icon-info" }),
                        _c("span", {
                          staticStyle: { "word-break": "break-word" },
                          domProps: { innerHTML: _vm._s(_vm.alertText) },
                        }),
                      ]),
                      _c("h3", {
                        staticClass: "text-c",
                        staticStyle: { "font-size": "16px" },
                        domProps: { innerHTML: _vm._s(_vm.introduction) },
                      }),
                      _c(
                        "el-form",
                        {
                          ref: "passwordForm",
                          staticClass: "demo-ruleForm",
                          attrs: {
                            model: _vm.loginForm,
                            "label-width": "100px",
                            "label-position": "top",
                            "hide-required-asterisk": true,
                          },
                          nativeOn: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "password",
                                label: "Password",
                                rules: [
                                  {
                                    required: true,
                                    message: "Password is required",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "password" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.LogIn.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.loginForm.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.loginForm, "password", $$v)
                                  },
                                  expression: "loginForm.password",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { loading: _vm.LoginLoading, type: "primary" },
                          on: { click: _vm.LogIn },
                        },
                        [_vm._v("Log In")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showMessage
                ? _c("div", { staticClass: "message-box" }, [
                    _c("div", {
                      staticClass: "applicant-message",
                      attrs: { type: "warning" },
                      domProps: { innerHTML: _vm._s(_vm.showMessageText) },
                    }),
                  ])
                : _vm._e(),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }