import { getTokenTime } from '@/utils/auth'
import store from '@/store'
const timeOut = 10 * 60 * 1000 // 10 minutes
export function resetTokenFunc() {
  const current = new Date().getTime()
  const previous = getTokenTime()
  if (current - previous >= timeOut) {
    store.dispatch('user/refreshTokenTime')
  }
}
