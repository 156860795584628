var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { display: "flex", "justify-content": "flex-end" } },
    [
      _c(
        "span",
        { staticStyle: { margin: "2px" } },
        [
          _c(
            "el-button",
            {
              staticStyle: {
                color: "white",
                background: "#37b24d !important",
                "border-color": "#37b24d",
              },
              attrs: { type: "secondary" },
              on: { click: _vm.handleShowDialog },
            },
            [
              _c("i", {
                staticClass: "fa fa-gavel",
                staticStyle: { "margin-right": "10px" },
              }),
              _vm._v("Police Report"),
            ]
          ),
        ],
        1
      ),
      _c(
        "span",
        [
          _c(
            "el-dialog",
            {
              ref: "dialogEntity",
              staticClass: "dialog",
              attrs: {
                "lock-scroll": false,
                "append-to-body": true,
                visible: _vm.showDialog,
                width: _vm.device === "mobile" ? "90%" : "900px",
                "before-close": _vm.clearTimer,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showDialog = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "dialog-title f-w-600",
                      attrs: { slot: "title" },
                      slot: "title",
                    },
                    [_vm._v("PBS Ref " + _vm._s(_vm.referenceNumber))]
                  ),
                  _c("div", { staticClass: "status" }, [
                    _vm._v(_vm._s(_vm.dialogData.checkStatusLabel)),
                  ]),
                  _vm.dialogData.showResult
                    ? _c(
                        "div",
                        {
                          staticClass: "text-l",
                          staticStyle: {
                            background: "#F1F5F9",
                            "border-radius": "5px",
                            border: "#E2E8F0 solid 1px",
                            padding: "20px",
                            margin: "10px 0",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                                "align-items": "center",
                              },
                            },
                            [
                              _c("span", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "f-w-600",
                                    staticStyle: { "margin-bottom": "5px" },
                                  },
                                  [_vm._v("Police Result")]
                                ),
                                _vm.dialogData.resultType === "DCO"
                                  ? _c("div", [
                                      _vm._v(
                                        "Disclosable Court Outcomes (DCO)"
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.dialogData.resultType === "NDCO"
                                  ? _c("div", [
                                      _vm._v(
                                        "No Disclosable Court Outcomes (NDCO)"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c(
                                "span",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { loading: _vm.buttonLoading },
                                      on: { click: _vm.ViewReport },
                                    },
                                    [_vm._v("Download")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm.showConfirmation
                            ? _c(
                                "div",
                                { staticClass: "text-l" },
                                [
                                  _c("el-divider"),
                                  _c("p", [
                                    _vm._v(
                                      "Please review above police result and confirm your need of dispute."
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticStyle: { margin: "10px 0" } },
                                    [
                                      _vm._v(
                                        "The system will automatically confirm the result without any need for dispute after "
                                      ),
                                      _c("b", [
                                        _vm._v(_vm._s(_vm.formattedTime)),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { margin: "10px 0" } },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          "Would you like to raise a dispute"
                                        ),
                                      ]),
                                      _c(
                                        "el-radio-group",
                                        {
                                          staticStyle: { "margin-top": "10px" },
                                          model: {
                                            value: _vm.disputeSelection,
                                            callback: function ($$v) {
                                              _vm.disputeSelection = $$v
                                            },
                                            expression: "disputeSelection",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: false } },
                                            [_vm._v("No")]
                                          ),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: true } },
                                            [_vm._v("Yes")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.disputeSelection
                                    ? _c(
                                        "span",
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder:
                                                "Please provide details about this dispute.",
                                              type: "textarea",
                                              maxlength: "3000",
                                              autosize: { minRows: 4 },
                                            },
                                            model: {
                                              value: _vm.disputeInput,
                                              callback: function ($$v) {
                                                _vm.disputeInput = $$v
                                              },
                                              expression: "disputeInput",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: {
                                        disabled:
                                          _vm.disputeSelection === null ||
                                          (_vm.disputeSelection &&
                                            _vm.disputeInput === ""),
                                        type: "primary",
                                      },
                                      on: { click: _vm.handleConfirm },
                                    },
                                    [_vm._v("Confirm")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _vm.dialogData.events.length > 0
                    ? _c(
                        "span",
                        [
                          _c(
                            "div",
                            {
                              staticClass: "f-w-600",
                              staticStyle: { "margin-top": "20px" },
                            },
                            [_vm._v("Event History")]
                          ),
                          _c(
                            "el-table",
                            {
                              ref: "tTable",
                              staticStyle: {
                                width: "100%",
                                "margin-top": "10px",
                              },
                              attrs: {
                                data: _vm.dialogData.events,
                                "highlight-current-row": "",
                                height: "350",
                                border: "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "createdOn",
                                  label: "Time",
                                  width: "170",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-weight": "500",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.convertUTC2LocalDateTime(
                                                    scope.row.createdOn
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  330431538
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "eventType", label: "Event" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-weight": "500",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(scope.row.eventType)
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-style": "italic",
                                              },
                                            },
                                            [_vm._v(_vm._s(scope.row.notes))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  575233019
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }