<script>
import Welcome from '@/views/Edit/Welcome'
import ReopenNotes from '@/views/Edit/ReopenNotes'
import AuthorisedRepresentative from '@/views/Edit/AuthorisedRepresentative'
import AddressHistory from '@/views/Edit/AddressHistory'
import OtherCountries from '@/views/Edit/OtherCountries'
import CoIDeclaration from '@/views/Edit/CoIDeclaration'
import CoIDeclarationNAB from '@/views/Edit/CoIDeclarationNAB'
import EYQuestionG1_3 from '@/views/Edit/EYQuestionG1_3'
import EYQuestionG4 from '@/views/Edit/EYQuestionG4'
import EYQuestion from '@/views/Edit/EYQuestion'
import CVUpload from '@/views/Edit/CVUpload'
import ComplianceReference from '@/views/Edit/ComplianceReference'
import ElectronicLodgement from '@/views/Edit/ElectronicLodgement'
import EmploymentHistory from '@/views/Edit/EmploymentHistory'
import EmploymentReference from '@/views/Edit/EmploymentReference'
import EntitlementToWork from '@/views/Edit/EntitlementToWork'
import EntitlementToWork2 from '@/views/Edit/EntitlementToWork2'
import Identity100Points from '@/views/Edit/Identity100Points'
import ManagementDirector from '@/views/Edit/ManagementDirector'
import MedicalDeclaration from '@/views/Edit/MedicalDeclaration'
import NotImplemented from '@/views/Edit/NotImplemented'
import PersonalDetails from '@/views/Edit/PersonalDetails'
import PersonalDetailsInternational from '@/views/Edit/PersonalDetailsInternational'
import ProfessionalMemberships from '@/views/Edit/ProfessionalMemberships'
import ProofOfIdentity from '@/views/Edit/ProofOfIdentity'
import QualificationsNAB from '@/views/Edit/QualificationsNAB'
import QualificationsTertiary from '@/views/Edit/QualificationsTertiary'
import QualificationsTrade from '@/views/Edit/QualificationsTrade'
import RegisteredTaxAgent from '@/views/Edit/RegisteredTaxAgent'
import VulnerablePeople from '@/views/Edit/VulnerablePeople'
import Confirmation from '@/views/Edit/Confirmation'
import AdobeSign from '@/views/Edit/AdobeSign'
import ThankYou from '@/views/Edit/ThankYou'
import Covid19Vaccination from '@/views/Edit/Covid19Vaccination'
import RequiredDeclarationsNAB from '@/views/Edit/RequiredDeclarationsNAB/index.vue'
import PoliceResultSharingConsent from '@/views/Edit/PoliceResultSharingConsent/index.vue'
import {
  backNextApi,
  freshSidebarApi,
  onApplicantChangeApi,
  onFormDataSaveApi,
  showMessageApi,
  submitApi
} from '@/api/application'
import { mapState } from 'vuex'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'

export default {
  name: 'ApplicationView',
  components: {
    Welcome,
    ReopenNotes,
    AuthorisedRepresentative,
    AddressHistory,
    OtherCountries,
    CoIDeclaration,
    CoIDeclarationNAB,
    RequiredDeclarationsNAB,
    EYQuestionG1_3,
    EYQuestionG4,
    EYQuestion,
    CVUpload,
    ComplianceReference,
    ElectronicLodgement,
    EmploymentHistory,
    EmploymentReference,
    EntitlementToWork,
    EntitlementToWork2,
    Identity100Points,
    ManagementDirector,
    MedicalDeclaration,
    NotImplemented,
    PersonalDetails,
    PersonalDetailsInternational,
    ProfessionalMemberships,
    ProofOfIdentity,
    QualificationsNAB,
    QualificationsTertiary,
    QualificationsTrade,
    RegisteredTaxAgent,
    VulnerablePeople,
    Confirmation,
    AdobeSign,
    ThankYou,
    Covid19Vaccination,
    PoliceResultSharingConsent
  },
  provide() {
    return {
      BackNext: this.BackNext,
      OnApplicantChange: this.OnApplicantChange,
      OnFormDataSave: this.OnFormDataSave,
      OnRefreshSidebar: this.OnRefreshSidebar,
      Submit: this.Submit,
      ContinueSetSession: this.ContinueSetSession,
      SaveFormDataSetSession: this.SaveFormDataSetSession,
      SetApplicant: this.SetApplicant
    }
  },
  data() {
    return {
      showMessage: false,
      showMessageText: ''
    }
  },
  computed: {
    ...mapState({
      section: (state) => state.section.section,
      preparation: state => state.preparation.preparation
    }),
    Session() {
      return this.preparation.session
    }
  },
  async created() {
    // await PubSubHub.start()
    // await PubSubHub.client.send('CreateApplicant')
    await this.Preparation()
  },
  methods: {
    async Preparation() {
      showMessageApi().then(async res => {
        const { data } = res
        this.showMessage = data.showMessage
        this.showMessageText = data.showMessageText
      })
      const params = {
        session: this.$route.params.data ? this.$route.params.data.session
          : (this.$getPreparation() ? this.$getPreparation().session : null)
      }
      this.$store.dispatch('preparation/getPreparation', params).then(async() => {
        // const applicantId = this.preparation.session.current.applicantId
        // const referenceNumber = this.preparation.session.current.referenceNumber
        // const isAdmin = this.preparation.session.current.isAdmin
        // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
        //   await PubSubHub.client.send('RegisterApplicant', applicantId, referenceNumber, isAdmin)
        // }
        if (this.preparation.goSelect) {
          this.$router.push({ path: 'select' })
        }
      })
    },
    ContinueSetSession(session) {
      const data = this.preparation
      data.session = session
      this.$store.dispatch('preparation/setPreparation', data).then(() => {
        this.$store.dispatch('section/set_section', { section: data.session.current.formSection.blockName })
      })
    },
    SaveFormDataSetSession(session) {
      const data = this.preparation
      data.session = session
      this.$store.dispatch('preparation/setPreparation', data).then(() => {
        this.$store.dispatch('section/set_section', { section: data.session.current.formSection.blockName })
      })
    },
    SetApplicant(applicant) {
      const data = this.preparation
      data.applicant = applicant
      this.$store.dispatch('preparation/setPreparation', data)
    },

    async BackNext(params) {
      await backNextApi(params).then((res) => {
        const data = this.preparation
        data.session = res.data.session
        this.$store.dispatch('preparation/setPreparation', data).then(() => {
          this.$store.dispatch('section/set_section', { section: data.session.current.formSection.blockName })
        })
      })
    },

    async OnApplicantChange(params) {
      await onApplicantChangeApi(params).then((res) => {
        const data = this.preparation
        data.applicant = params.applicant
        data.session = res.data.session
        this.$store.dispatch('preparation/setPreparation', data)
      })
    },

    async OnFormDataSave(params) {
      await onFormDataSaveApi(params).then((res) => {
        if (res.data && res.data.session) {
          this.SaveFormDataSetSession(res.data.session)
        }
      })
    },

    async OnRefreshSidebar(params) {
      await freshSidebarApi(params).then((res) => {
      })
    },
    async Submit(params) {
      await submitApi(params).then((res) => {
      })
    },
    isShow(name) {
      // return true
      if (name === 'PersonalDetails') {
        // Session.Current.FormSection.BlockName = "PersonalDetails" and Session.UsePersonalDetailsInternational = False
        return this.section === 'PersonalDetails' && this.Session.usePersonalDetailsInternational === false
      } else if (name === 'PersonalDetailsInternational') {
        // Session.Current.FormSection.BlockName = "PersonalDetailsInternational" or (Session.Current.FormSection.BlockName = "PersonalDetails" and Session.UsePersonalDetailsInternational = True)
        return this.section === 'PersonalDetailsInternational' || (this.section === 'PersonalDetails' && this.Session.usePersonalDetailsInternational === true)
      } else if (name === 'EntitlementToWork') {
        // Session.Current.FormSection.BlockName = "EntitlementToWork" and Session.UseEntitlementToWork2 = False
        return this.section === 'EntitlementToWork' && this.Session.useEntitlementToWork2 === false
      } else if (name === 'EntitlementToWork2') {
        // Session.Current.FormSection.BlockName = "EntitlementToWork" and Session.UseEntitlementToWork2 = True
        return (this.section === 'EntitlementToWork' && this.Session.useEntitlementToWork2 === true) || this.section === name
      } else {
        return this.section === name
      }
    }
  }
}
</script>

<template>
  <div class="ApplicationView" lang="en">
    <div v-if="showMessage" class="applicant-message" v-html="showMessageText" />
    <Welcome v-if="isShow('Welcome')" />
    <AuthorisedRepresentative v-if="isShow('AuthorisedRepresentative')" />
    <ReopenNotes v-if="isShow('ReopenNotes')" />
    <AddressHistory v-if="isShow('AddressHistory')" />
    <OtherCountries v-if="isShow('OtherCountries')" />
    <CoIDeclaration v-if="isShow('CoIDeclaration')" />
    <CoIDeclarationNAB v-if="isShow('CoIDeclarationNAB')" />
    <RequiredDeclarationsNAB v-if="isShow('RequiredDeclarationsNAB')" />
    <EYQuestion v-if="isShow('EYQuestion')" />
    <EYQuestionG1_3 v-if="isShow('EYQuestionG1_3')" />
    <EYQuestionG4 v-if="isShow('EYQuestionG4')" />
    <CVUpload v-if="isShow('CVUpload')" />
    <ComplianceReference v-if="isShow('ComplianceReference')" />
    <ElectronicLodgement v-if="isShow('ElectronicLodgement')" />
    <EmploymentHistory v-if="isShow('EmploymentHistory')" />
    <EmploymentReference v-if="isShow('EmploymentReference')" />
    <EntitlementToWork v-if="isShow('EntitlementToWork')" />
    <EntitlementToWork2 v-if="isShow('EntitlementToWork2')" />
    <Identity100Points v-if="isShow('Identity100Points')" />
    <ManagementDirector v-if="isShow('ManagementDirector')" />
    <MedicalDeclaration v-if="isShow('MedicalDeclaration')" />
    <NotImplemented v-if="isShow('NotImplemented')" />
    <PersonalDetails v-if="isShow('PersonalDetails')" />
    <PersonalDetailsInternational v-if="isShow('PersonalDetailsInternational')" />
    <ProfessionalMemberships v-if="isShow('ProfessionalMemberships')" />
    <ProofOfIdentity v-if="isShow('ProofOfIdentity')" />
    <QualificationsNAB v-if="isShow('QualificationsNAB')" />
    <QualificationsTertiary v-if="isShow('QualificationsTertiary')" />
    <QualificationsTrade v-if="isShow('QualificationsTrade')" />
    <RegisteredTaxAgent v-if="isShow('RegisteredTaxAgent')" />
    <VulnerablePeople v-if="isShow('VulnerablePeople')" />
    <Confirmation v-if="isShow('Confirmation')" />
    <AdobeSign v-if="isShow('AdobeSign')" />
    <ThankYou v-if="isShow('ThankYou')" />
    <Covid19Vaccination v-if="isShow('Covid19Vaccination')" />
    <PoliceResultSharingConsent v-if="isShow('PoliceResultSharingConsent')" />
  </div>
</template>

<style lang="scss" scoped>
.ApplicationView {
  width: 100%;

  div {
    background-color: #fff;
    padding: 24px;
    margin-bottom: 10px;
    line-height: 1.5;
  }
}

.applicant-message {
  font-size: 15px;
  background: #2974b2 !important;
  color: white;
}
</style>
